import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Tabs from "../../components/ui-core/TabsND";
import { Search } from "@material-ui/icons";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import { isEqualData } from "../../helpers/DataUtils";
import fp from "lodash/fp";
import { Map } from "immutable";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../../components/ui-core/FlexBox";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import DataListFilter from "../../helpers/DataListFilter";
import _ from "lodash";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import { toCustomsFilter } from "../../helpers/CustomsFilterMapper";
import BatchUpdateFilterForm from "./BatchUpdateFilterForm";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  tabs: {
    flexGrow: 1,
  },
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    marginBottom: 10,
    marginTop: 10,
  },
});

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const { stream: onChangeStream, handler: onChange } = createEventHandler();

    const filterChangedStream = onChangeStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .do(([changedValue, props]) =>
        props.onFilterChange(
          toCustomsFilter(
            fp.merge(fp.get("location.query", props), {
              ...changedValue,
              size: 20,
            }),
          ),
        ),
      )
      .startWith(Map());

    const chipsStream = propsStream
      .map(props => props.filter && props.filter.getSearch())
      .distinctUntilChanged()
      .map(parseString)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(chipsStream, filterChangedStream, (props, chips) => ({
        ...props,
        chips,
        onChange,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminOrderBatchUpdateListHeaderWrapper.propTypes = {
  tabItems: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf({
      title: PropTypes.string,
      value: PropTypes.any,
      icon: PropTypes.element,
    }),
  ]),
  tabValue: PropTypes.any,
  buttons: PropTypes.elementType,
  getLocalisationMessage: PropTypes.func,
  onChange: PropTypes.func,
  clearSelectedItems: PropTypes.func,
  onFilterChange: PropTypes.func,
  moreMenu: PropTypes.element,
  filter: PropTypes.instanceOf(DataListFilter),
  chips: PropTypes.array,
};

function AdminOrderBatchUpdateListHeaderWrapper({
  getLocalisationMessage,
  buttons,
  moreMenu,
  onChange,
  clearSelectedItems,
  tabItems,
  tabValue,
  chips,
  filter,
  onFilterChange,
}) {
  const { root, search, tabs } = useStyles();
  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  return (
    <div className={root}>
      <div className={tabs}>
        <Tabs
          variant="scrollable"
          items={tabItems}
          value={tabValue}
          onChange={(event, value) => {
            clearSelectedItems();
            onChange({ type: value });
          }}
        />
      </div>
      <FlexBox>{buttons}</FlexBox>
      <FlexBox className={search} ref={ref}>
        <Search
          fontSize="large"
          style={{
            color: "rgba(0, 0, 0, 0.23)",
            top: ".5rem",
            left: ".5rem",
            position: "absolute",
          }}
        />
        <ChipTextField
          fullWidth={true}
          addOnBlur={false}
          clearOnBlur={false}
          value={chips}
          style={{
            padding: ".5rem 1rem 0 3rem",
            maxHeight: divHeight > 86 && "5.8rem",
            overflow: divHeight > 86 && "auto",
          }}
          disableUnderline={true}
          hintText={getLocalisationMessage("search", "Search")}
          onChange={value =>
            onFilterChange(filter.setSearch(stringifyArray(value)))
          }
        />
      </FlexBox>
      <FlexBox justify="flex-end">
        <BatchUpdateFilterForm
          initialValues={{
            fromDateTime: safeParseDate(filter.getValue("from_date_time")),
            toDateTime: safeParseDate(filter.getValue("to_date_time")),
          }}
          onSubmit={values => {
            onFilterChange(
              filter.withMutations((x: DataListFilter) => {
                x.setValueMap({
                  from_date_time: formatDateTimeToUrl(values.fromDateTime),
                  to_date_time: formatDateTimeToUrl(values.toDateTime),
                });
              }),
            );
          }}
        />
      </FlexBox>
      {moreMenu}
      {/* <IconButton style={{ marginLeft: 12 }}>
                <Filter />
            </IconButton> */}
      {/* <IconButton style={{ marginLeft: 4, marginRight: 10 }}>
        <MoreVert />
      </IconButton> */}
    </div>
  );
}

AdminOrderBatchUpdateListHeaderWrapper.propTypes = {};

export default enhancer(AdminOrderBatchUpdateListHeaderWrapper);
