import React, { useState } from "react";
import PropTypes from "prop-types";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { REPORTS_CN_38_URL } from "../../constants/AdminPathConstants";
import CN38Form from "../../components/orders-core/CN38Form";
import {
  CN_37_PDF_URL,
  CN_38_PDF_URL,
  CN_41_PDF_URL,
  createCn38,
} from "../../api/admin/AdminIpsReportsApi";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { getPDF } from "../../helpers/FormUtils";
import _ from "lodash";
import { getUserId } from "../../reducers/ProfileReducer";
import {
  formatDateTimeToUrl,
  formatDateToUrl,
} from "../../helpers/FormatUtils";

export const localisationCategory = (
  option,
  res,
  message,
  cn38PDF,
  setCn38PDF,
  cn37PDF,
  setCn37PDF,
  cn41PDF,
  setCn41PDF,
) => {
  switch (option) {
    case "A":
      return getPDF(
        cn38PDF,
        setCn38PDF,
        IS_PRINTING,
        _.get(res, "data"),
        message,
      );

    case "B":
      return getPDF(
        cn41PDF,
        setCn41PDF,
        IS_PRINTING,
        _.get(res, "data"),
        message,
      );

    case "C":
      return getPDF(
        cn37PDF,
        setCn37PDF,
        IS_PRINTING,
        _.get(res, "data"),
        message,
      );

    case "D":
      return getPDF(
        cn37PDF,
        setCn37PDF,
        IS_PRINTING,
        _.get(res, "data"),
        message,
      );

    default:
      return null;
  }
};

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
      userId: getUserId(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

function AdminCN38ReportsCreateContainer(props) {
  const [cn38PDF, setCn38PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_38_PDF_URL,
  });

  const [cn37PDF, setCn37PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_37_PDF_URL,
  });

  const [cn41PDF, setCn41PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_41_PDF_URL,
  });

  const [isLoading, setIsLoading] = useState(false);

  return (
    <AdminAppLayout
      title={props.i18n.get("create_new_cn_38", "Create new CN 38")}
    >
      <CN38Form
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setLocation={props.setLocation}
        initialValues={{
          operator: {
            id: props.userId,
          },
          items: [],
        }}
        onSubmit={values => {
          setIsLoading(true);
          createCn38({
            ...toSnakeCase(values),
            date: formatDateToUrl(values.date),
            dispatch_date_time: formatDateTimeToUrl(values.dispatchDateTime),
          })
            .then(res => {
              if (res && res.status === "success") {
                props.showSuccessMessage(
                  props.i18n.get("successfully_created"),
                );
                setIsLoading(false);

                localisationCategory(
                  values.category,
                  res,
                  props.showErrorMessage,
                  cn38PDF,
                  setCn38PDF,
                  cn37PDF,
                  setCn37PDF,
                  cn41PDF,
                  setCn41PDF,
                );
                props.setLocation(REPORTS_CN_38_URL);
              }
            })
            .catch(error => {
              setIsLoading(false);
              props.showErrorMessage(error);
            });
        }}
      />
    </AdminAppLayout>
  );
}

AdminCN38ReportsCreateContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  userId: PropTypes.func,
};

export default enhancer(AdminCN38ReportsCreateContainer);
