import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import {
  HOLD_ON_AT_CUSTOMS,
  RETURNED_FROM_CUSTOMS,
} from "../../constants/OrderStatusCodes";
import { OrderedSet } from "immutable";

const options = OrderedSet.of(HOLD_ON_AT_CUSTOMS, RETURNED_FROM_CUSTOMS);

const valueSelector = formValueSelector("OrderCustomsBatchUpdate");
const validateOrderBarcode = /^(?=.*[A-Za-z])(?=.*\d).+$/;

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      orderBarcodes: valueSelector(state, "orderBarcodes"),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "OrderCustomsBatchUpdate",
    enableReinitialize: true,
    validate: (values, props) => ({
      orderBarcodes:
        fp.size(values.orderBarcodes) === 0
          ? props.getLocalisationMessage("select_orders")
          : values.orderBarcodes.filter(
              item => !validateOrderBarcode.test(item),
            ).length > 0 &&
            props.getLocalisationMessage("please_provide_order_barcodes"),
      orderStatus:
        !values.orderStatus &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("select_status", "Select Status"),
    }),
  }),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
);

OrderCustomsBatchUpdate.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
};

function OrderCustomsBatchUpdate(props) {
  const { getLocalisationMessage, classes } = props;

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 1000,
          maxWidth: 1000,
          minHeight: 200,
        },
      }}
    >
      <form onSubmit={props.handleSubmit}>
        <PageLoading isLoading={props.submitting} />
        <DialogTitle
          style={{ color: props.theme.palette.appBarTextColor }}
          className={classes.dialogTitle}
        >
          {getLocalisationMessage(
            "update_customs_status",
            "Update customs status",
          )}
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <FlexBox container={8} style={{ gap: 16 }}>
            <FlexBox style={{ width: "70%" }}>
              <FormChipInput
                name="orderBarcodes"
                focus={true}
                margin="normal"
                fullWidth={true}
                label={getLocalisationMessage("orders_barcodes")}
              />
            </FlexBox>

            <FlexBox style={{ width: "30%", gap: 16 }} direction="column">
              <FormAutoComplete
                name="orderStatus"
                fullWidth={true}
                margin="normal"
                size="medium"
                label={getLocalisationMessage("status", "Status")}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
                options={options}
                formatOption={option =>
                  formatOrderStatusCodeForLocalisation(
                    option,
                    getLocalisationMessage,
                  )
                }
              />
            </FlexBox>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onRequestClose} primary={true}>
            {getLocalisationMessage("dismiss")}
          </Button>

          <Button type="submit" primary={true}>
            {getLocalisationMessage("submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default enhancer(OrderCustomsBatchUpdate);
