import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import FlexBox from "../ui-core/FlexBox";
import { getFormValues, reduxForm } from "redux-form";
import FormTextField from "../form/FormTextField";
import { formatText } from "../../helpers/FormatUtils";
import FormAutocompleteNew from "../form/FormInternationalSettingsAutocomplete";
import {
  getCacheRegion,
  getInternationalRegionChoose,
} from "../../api/admin/AdminInternationalReportsApi";
import { getValue } from "../../helpers/DataUtils";
import {
  getCacheMailClass,
  getMailClassChoose,
} from "../../api/admin/AdminIpsReportsApi";
import FormSelectField from "../form/FormSelectField";
import FormMailClassAutocomplete from "../form/FormMailClassAutocomplete";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { Add } from "@material-ui/icons";
import fp from "lodash/fp";
import FormInternationalDepartureAutocomplete from "../form/FormInternationalDepartureAutocomplete";

const categories = ["A", "B", "C", "D"];

export const localisationCategory = (option, getLocalisationMessage) => {
  switch (option) {
    case "A":
      return "Авиа или Приоритетная почта";

    case "B":
      return "S.A.L. (Неприоритетеная почта)";

    case "C":
      return "Наземная почта (Неприоритетеная почта)";

    case "D":
      return "Приоритетная почта направленная наземным путен";

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

export const fieldNameWeight = option => {
  switch (option) {
    case "T":
      return "weightTara";

    case "U":
      return "weightLetters";

    case "C":
      return "weightCp";

    case "E":
      return "weightEms";

    default:
      return "";
  }
};

export const fieldNameCount = option => {
  switch (option) {
    case "T":
      return "qtyTara";

    case "U":
      return "qtyLetters";

    case "C":
      return "qtyCp";

    case "E":
      return "qtyEms";

    default:
      return "";
  }
};

const getValues = getFormValues("CN38ItemsForm");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "CN38ItemsForm",
    enableReinitialize: true,
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
        mailClass: values.mailClass,
      },
    })),
  ),
);

CN38ItemsForm.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CN38ItemsForm(props) {
  return (
    <form style={{ height: "100%" }}>
      <FlexBox direction="column" style={{ gap: 16, height: "100%" }}>
        <Card style={{ height: "100%", padding: 20, overflow: "auto" }}>
          <CardContent>
            <FlexBox flex={true} direction="column">
              <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
                <FlexBox style={{ gap: 13 }} justify="center" align="center">
                  <Typography style={{ width: 250, textAlign: "right" }}>
                    Nº отправления
                  </Typography>

                  <FormTextField name="barcode" fullWidth={true} />
                </FlexBox>

                <FlexBox style={{ gap: 13 }} justify="center" align="center">
                  <Typography style={{ width: 250, textAlign: "right" }}>
                    Nº депеши
                  </Typography>

                  <FormTextField
                    name="depecheNumber"
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>

                <FlexBox style={{ gap: 13 }} justify="center" align="center">
                  <Typography style={{ width: 250, textAlign: "right" }}>
                    Вид отправления
                  </Typography>

                  <FormMailClassAutocomplete
                    getById={getCacheMailClass}
                    chooseAPI={getMailClassChoose}
                    name="mailClass"
                    fullWidth={true}
                  />
                </FlexBox>

                <FlexBox style={{ gap: 13 }} justify="center" align="center">
                  <Typography style={{ width: 250, textAlign: "right" }}>
                    Откуда
                  </Typography>

                  <FlexBox style={{ gap: 13, width: "100%" }}>
                    <FormInternationalDepartureAutocomplete
                      getById={getCacheRegion}
                      chooseAPI={getInternationalRegionChoose}
                      name="departure"
                      type="CN38"
                      fullWidth={true}
                      renderOption={option => (
                        <FlexBox direction="column">
                          <span>{getValue(option, "value.name", " ")}</span>
                        </FlexBox>
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox style={{ gap: 13 }} justify="center" align="center">
                  <Typography style={{ width: 250, textAlign: "right" }}>
                    Куда
                  </Typography>

                  <FlexBox style={{ gap: 13, width: "100%" }}>
                    <FormAutocompleteNew
                      getById={getCacheRegion}
                      chooseAPI={getInternationalRegionChoose}
                      name="destination"
                      type="CN38"
                      fullWidth={true}
                      renderOption={option => (
                        <FlexBox direction="column">
                          <span>{getValue(option, "value.name", " ")}</span>
                        </FlexBox>
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox style={{ gap: 13 }} justify="center" align="center">
                  <Typography style={{ width: 250, textAlign: "right" }}>
                    Категория почты{" "}
                  </Typography>

                  <FormSelectField
                    name="category"
                    fullWidth={true}
                    options={categories}
                    formatOption={option =>
                      localisationCategory(option, props.getLocalisationMessage)
                    }
                  />
                </FlexBox>

                <FlexBox style={{ gap: 13 }} justify="center" align="center">
                  <Typography style={{ width: 250, textAlign: "right" }}>
                    Вес отправления
                  </Typography>

                  <FormTextField
                    name={fieldNameWeight(
                      getValue(props.values, "mailClass.code"),
                    )}
                    fullWidth={true}
                    disabled={!getValue(props.values, "mailClass.code")}
                    type="number"
                  />
                </FlexBox>

                <FlexBox style={{ gap: 13 }} justify="center" align="center">
                  <Typography style={{ width: 250, textAlign: "right" }}>
                    Количество
                  </Typography>

                  <FormTextField
                    name={fieldNameCount(
                      getValue(props.values, "mailClass.code"),
                    )}
                    disabled={!getValue(props.values, "mailClass.code")}
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>

                <FlexBox style={{ gap: 13 }} justify="center" align="center">
                  <Typography style={{ width: 250, textAlign: "right" }}>
                    Примечание
                  </Typography>

                  <FormTextField
                    name="observation"
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </CardContent>

          <FlexBox flex={true} justify="flex-end" style={{ gap: 16 }}>
            <CardActions>
              <FlexBox justify="flex-end">
                <CustomButton
                  style={{ width: 200 }}
                  fullWidth={true}
                  variant={OUTLINED}
                  color={SECONDARY}
                  size="large"
                  onClick={props.onClose}
                >
                  {props.getLocalisationMessage("dismiss", "Dismiss")}
                </CustomButton>
              </FlexBox>

              <FlexBox>
                <CustomButton
                  style={{ width: 200 }}
                  fullWidth={true}
                  variant={CONTAINED}
                  color={SECONDARY}
                  onClick={() => props.onClick(props.values)}
                  size="large"
                  startIcon={<Add />}
                >
                  {props.getLocalisationMessage("add", "Add")}
                </CustomButton>
              </FlexBox>
            </CardActions>
          </FlexBox>
        </Card>
      </FlexBox>
    </form>
  );
}

export default enhancer(CN38ItemsForm);
