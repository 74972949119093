import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";
import sprintf from "sprintf";
import DataListFilter from "../../helpers/DataListFilter";
import { ObservableCache } from "../../helpers/loading-cache";
import fp from "lodash/fp";
import { fromJS } from "immutable";

const FLIGHTS_CHOOSE_URL = `${API_ROOT_URL}/admin/flights/choose`;
const FLIGHTS_ITEM_URL = `${API_ROOT_URL}/admin/flights/%s`;

const AIRPORTS_CHOOSE_URL = `${API_ROOT_URL}/admin/airports/choose`;
const AIRPORTS_ITEM_URL = `${API_ROOT_URL}/admin/airports/%s`;

const MAIL_CLASS_CHOOSE_URL = `${API_ROOT_URL}/admin/mail_class`;
const MAIL_CLASS_ITEM_URL = `${API_ROOT_URL}/admin/mail_class/%s`;

const flightsCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(FLIGHTS_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getCacheFlights = id => flightsCache.get(id);

export const getFlightsChoose = (request: DataListFilter) =>
  api.getStream(FLIGHTS_CHOOSE_URL, {
    params: request.getDefinedValues(),
  });

const mailClassCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(MAIL_CLASS_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getCacheMailClass = id => mailClassCache.get(id);

export const getMailClassChoose = (request: DataListFilter) =>
  api.getStream(MAIL_CLASS_CHOOSE_URL, {
    params: request.getDefinedValues(),
  });

const airportsCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(AIRPORTS_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getCacheAirports = id => airportsCache.get(id);

export const getAirportsChoose = (request: DataListFilter) =>
  api.getStream(AIRPORTS_CHOOSE_URL, {
    params: request.getDefinedValues(),
  });

// creation

const CN_38_URL = `${API_ROOT_URL}/admin/cn38`;
export const CN_37_PDF_URL = `${API_V2_ROOT_URL}/cn37`;
export const CN_38_PDF_URL = `${API_V2_ROOT_URL}/cn38`;
export const CN_41_PDF_URL = `${API_V2_ROOT_URL}/cn41`;

export const getCn38List = params =>
  api.get(CN_38_URL, {
    params,
  });

export const createCn38 = body =>
  api.post(CN_38_URL, {
    body,
  });

export const editCn38 = (id, body) =>
  api.put(`${CN_38_URL}/${id}`, {
    body,
  });

export const getCn38 = id => api.get(`${CN_38_URL}/${id}`);
