import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router";
import { getMessage } from "../../reducers/LocalizationReducer";
import { USERS_LIST_URL } from "../../constants/AdminPathConstants";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import DataListFilter from "../../helpers/DataListFilter";

const enhancer = compose(
  reduxForm({
    form: "WarehouseItemUsersForm",
    enableReinitialize: true,
  }),
  useSheet({
    container: { flex: "1 1 0%", padding: "6px", display: "flex" },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

WarehouseItemOperatorsList.propTypes = {
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  getLocalisationMessage: PropTypes.func.isRequired,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  onFilterChange: PropTypes.func.isRequired,
};

function WarehouseItemOperatorsList(props) {
  const { getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");
  return (
    <DataList
      filter={props.filter}
      onFilterChange={props.onFilterChange}
      overscanRowCount={9}
      list={props.list}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      totalCount={props.total}
      rowGetter={row => props.list.get(row.index)}
    >
      <DataListColumn
        width={120}
        label={getLocalisationMessage("id", "ID")}
        dataKey="id"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <Link
            target="_blank"
            to={updateQuery(
              USERS_LIST_URL,
              fp.set("view", row.cellData.get("user_id")),
            )}
          >
            {row.cellData.get("user_id")}
          </Link>
        )}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("name", "Name")}
        dataKey="name"
        disableSort={true}
        cellRenderer={row => row.cellData.get("full_name") || NA}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("email", "Email")}
        dataKey="email"
        justifyContent="center"
        disableSort={false}
        cellRenderer={row => row.cellData.get("email") || NA}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("phone_number", "Phone number")}
        dataKey="phone"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row => row.cellData.get("phone") || NA}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("status", "Status")}
        dataKey="status"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row => row.cellData.get("status") || NA}
      />
    </DataList>
  );
}

export default enhancer(WarehouseItemOperatorsList);
