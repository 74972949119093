import React from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminMarketplaceDetailsMobileSettingsContainer from "./AdminMarketplaceDetailsMobileSettingsContainer";
import { getMarketplaceId } from "../../../reducers/MarketplaceReducer";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import Tabs from "../../../components/ui-core/Tabs";
import AdminMobileNotificationSettingsContainer from "../AdminMobileNotificationSettingsContainer";
import fp from "lodash/fp";

const SETTINGS = "settings";
const NOTIFICATION = "notification";

const tabs = [SETTINGS, NOTIFICATION];

const enhancer = compose(
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  connect(
    state => ({
      // values: getValues(state),
      marketplaceId: getMarketplaceId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminMobileSettingsContainer.propTypes = {
  setLocationQuery: PropTypes.func,
  location: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminMobileSettingsContainer(props) {
  const { getLocalisationMessage, location } = props;
  const tab = tabs.includes(location.query.view_tab)
    ? location.query.view_tab
    : tabs[0];

  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "mobile_app_settings",
        "Mobile app settings",
      )}
    >
      <Tabs
        value={tab}
        onChange={(e, v) => props.setLocationQuery(fp.set("view_tab", v))}
        tabs={[
          {
            label: getLocalisationMessage("settings", "settings"),
            value: SETTINGS,
          },
          {
            label: getLocalisationMessage("notifications", "Notifications"),
            value: NOTIFICATION,
          },
        ]}
      />
      {tab === SETTINGS && <AdminMarketplaceDetailsMobileSettingsContainer />}
      {tab === NOTIFICATION && <AdminMobileNotificationSettingsContainer />}
    </AdminAppLayout>
  );
}

export default enhancer(AdminMobileSettingsContainer);
