import React from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "@material-ui/core";
import { Link } from "react-router";
import FlexBox from "./FlexBox";
// import _ from 'lodash';

// const useStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: 'white',
//         height: 50,
//         color: '#555',
//     },
//     indicator: {
//         backgroundColor: theme.palette.primary.main,
//         height: 3,
//     },
// }));

const LinkTab = props => <Tab component={Link} {...props} />;

const SimpleTabs = props => (
  // const classes = useStyles();
  <Tabs {...props}>
    {props.items &&
      props.items.map((item, index) =>
        props.isLink ? (
          <LinkTab
            tabIndex={item.value}
            id={`nav-tab-${index}`}
            key={item.value}
            to={item.value}
            label={item.title}
            icon={item.icon}
            wrapped={true}
          />
        ) : (
          <Tab
            wrapped={true}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              flex: index === 3 && "1 1 auto",
            }}
            tabIndex={item.value}
            id={`tab-${index}`}
            key={index}
            value={item.value}
            // label={item.title}
            label={
              <FlexBox>
                {item.title}
                <span
                  style={{
                    color: item.value === "is_promised" ? "white" : "black",
                    minWidth: 24,
                    fontSize: 12,
                    padding: "0px 4px",
                    textAlign: "center",
                    marginLeft: 8,
                    marginRight: 8,
                    borderRadius: 50,
                    backgroundColor:
                      item.value === "is_promised" ? "red" : "white",
                    display: "inline-block",
                  }}
                >
                  {item.count}
                </span>
              </FlexBox>
            }
            icon={item.icon}
          />
        ),
      )}
  </Tabs>
);
SimpleTabs.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf({
      title: PropTypes.string,
      value: PropTypes.any,
      icon: PropTypes.element,
    }),
  ]),
  onChange: PropTypes.func,
  isLink: PropTypes.bool,
  variant: PropTypes.oneOf(["fullWidth", "scrollable", "standard"]),
  value: PropTypes.any,
};

SimpleTabs.defaultProps = {
  variant: "standard",
};

export default SimpleTabs;
