import React from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import CN38ItemsForm from "./CN38ItemsForm";

const enhancer = compose(
  useSheet({
    paper: { maxWidth: "560px", minWidth: "560px", minHeight: 100 },
  }),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

CN38ItemsDialog.propTypes = {
  classes: PropTypes.object,

  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
};

function CN38ItemsDialog(props) {
  const { classes } = props;

  return (
    <Dialog
      open={props.open}
      title={props.title}
      paperClassName={classes.paper}
      onClose={props.onClose}
    >
      <CN38ItemsForm onClick={props.onClick} onClose={props.onClose} />
    </Dialog>
  );
}

export default enhancer(CN38ItemsDialog);
