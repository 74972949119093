import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import { Map } from "immutable";
import MUITable, {
  COLUMN,
  DATE,
  DIALOG,
  RENDER,
} from "../../components/orders-core/MUITable";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { Add, Refresh } from "@material-ui/icons";
import { getValue } from "../../helpers/DataUtils";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_END,
} from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles } from "@material-ui/core";
import {
  createMobileNotificationSettings,
  getMobileNotificationSettings,
} from "../../api/admin/AdminMobileNotificationSettingsApi";
import MobileNotificationSettingsDialog from "../../components/order-create-wizard-new/international-reports/MobileNotificationSettingsDialog";
import _ from "lodash";
import { formatText } from "../../helpers/FormatUtils";
import MobileNotificationViewDialog from "../../components/order-create-wizard-new/international-reports/MobileNotificationViewDialog";
import { MOBILE_NOTIFICATION_SETTINGS_CREATE_URL } from "../../constants/AdminPathConstants";

export const localisation = (option, i18n) => {
  switch (option) {
    case "UZPOST":
      return i18n.get("customer_app", "Customer app");

    case "POSTMAN":
      return i18n.get("postman_app", "Postman app");

    default:
      return i18n.get(option, formatText(option));
  }
};

const useStyles = makeStyles({
  tabs: {
    margin: "0 -1rem",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  getContext({ setLocation: PropTypes.func }),
);

AdminMobileNotificationSettingsContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  setLocation: PropTypes.func,
};

function AdminMobileNotificationSettingsContainer(props) {
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    refresh: true,
    status: "all",
  });

  const classes = useStyles();

  const [id, setId] = useState(null);

  const refreshList = () => setFilter({ ...filter, refresh: !filter.refresh });

  useEffect(() => {
    setIsLoading(true);
    getMobileNotificationSettings(filter)
      .then(res => {
        if (res && res.data) {
          setList(getValue(res, "data.list", []));
          setTotal(getValue(res, "data.total", 0));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setList([]);
        setIsLoading(false);
      });
  }, [filter]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  return (
    <FlexBox>
      <MobileNotificationSettingsDialog
        open={open}
        title={props.i18n.get("new_notification", "New notification")}
        refreshList={refreshList}
        createApi={createMobileNotificationSettings}
        onRequestClose={() => {
          setOpen(false);
        }}
      />

      <MobileNotificationViewDialog
        open={id}
        id={id}
        onRequestClose={() => setId(null)}
      />

      <MUITable
        isLoading={isLoading}
        title={props.i18n.get("notifications", "Notifications")}
        size="medium"
        list={list}
        total={total}
        page={filter.page}
        setId={setId}
        rowsPerPage={filter.size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        withCheckbox={false}
        headerActions={
          <FlexBox justify={JUSTIFY_END} align={ALIGN_CENTER}>
            <CustomButton
              onClick={() =>
                props.setLocation(MOBILE_NOTIFICATION_SETTINGS_CREATE_URL)
              }
              startIcon={<Add />}
              variant={OUTLINED}
              color={SECONDARY}
              style={{
                marginRight: "1rem",
              }}
            >
              {props.i18n.get("create")}
            </CustomButton>

            <IconButton
              className={isLoading && classes.refresh}
              tooltip={props.i18n.get("refresh", "Refresh")}
              onClick={() => refreshList()}
            >
              <Refresh />
            </IconButton>
          </FlexBox>
        }
        columns={[
          {
            type: DIALOG,
            name: "id",
            keyName: "id",
            label: props.i18n.get("id"),
            align: "center",
          },
          {
            type: COLUMN,
            name: "title",
            label: props.i18n.get("title", "Title"),
          },
          {
            type: RENDER,
            name: "app_type",
            label: props.i18n.get("app", "App"),
            render: row => localisation(_.get(row, `app_type`, ""), props.i18n),
          },
          {
            type: DATE,
            align: "center",
            name: "created_date",
            keyName: "created_date",
            label: props.i18n.get("created_date", "Created date"),
          },
        ]}
      />
    </FlexBox>
  );
}

export default enhancer(AdminMobileNotificationSettingsContainer);
