import React, { useEffect, useState } from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { getValue } from "../../../helpers/DataUtils";
import CustomButton, { CONTAINED, SECONDARY } from "../../ui-core/CustomButton";
import { getMobileNotificationItem } from "../../../api/admin/AdminMobileNotificationSettingsApi";
import imgNotFound from "../../order-details-dialog/assets/notfound.png";
import { getFileUrl2 } from "../../../api/shared/FileApi";
import { formatDateTimeToUrl } from "../../../helpers/FormatUtils";
import reactHtmlParser from "react-html-parser";
import { DateRange } from "@material-ui/icons";
import FlexBox from "../../ui-core/FlexBox";

const enhancer = compose(
  useSheet({
    imageContainer: {
      minHeight: "150px",
      textAlign: "center",
      marginBottom: "6px",
      "& > img": { maxWidth: "250px", maxHeight: "200px" },
    },
  }),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

MobileNotificationViewDialog.propTypes = {
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  id: PropTypes.number,
};

function MobileNotificationViewDialog(props) {
  const { getLocalisationMessage, id } = props;
  const [data, setData] = useState({});
  const [photoUrl, setPhotoUrl] = useState(null);

  useEffect(() => {
    if (id) {
      getMobileNotificationItem(id).then(r => setData(r.data));
    }
  }, [id, props.open]);

  useEffect(() => {
    if (getValue(data, "photo.id")) {
      getFileUrl2(getValue(data, "photo.id"))
        .then(r => setPhotoUrl(r.data))
        .catch(() => setPhotoUrl(null));
    }
  }, [data]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onRequestClose();
        setPhotoUrl(null);
        setData({});
      }}
      PaperProps={{
        style: {
          minWidth: 500,
          maxWidth: 500,
        },
      }}
    >
      <DialogContent style={{ width: 500 }}>
        <FlexBox
          style={{
            gap: 10,
            marginBottom: 10,
            color: "rgba(0, 0, 0, 0.54",
            fontSize: "0.875rem",
          }}
          direction="row"
          align="center"
          flex={true}
        >
          <FlexBox align="center">
            <DateRange />
          </FlexBox>
          <FlexBox>
            {formatDateTimeToUrl(getValue(data, "created_date", ""))}
          </FlexBox>
        </FlexBox>
        <CardMedia
          style={{
            height: 250,
            objectFit: "cover",
          }}
          image={photoUrl || imgNotFound}
        />
        <CardContent style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Typography variant="h5" component="h2">
            {getValue(data, "title", "")}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              fontSize: "0.875rem",
              marginTop: 15,
            }}
          >
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                width: 450,
              }}
            >
              {reactHtmlParser(getValue(data, "message", ""))}
            </div>
          </Typography>
        </CardContent>
      </DialogContent>

      <DialogActions>
        <CustomButton
          variant={CONTAINED}
          color={SECONDARY}
          onClick={() => {
            props.onRequestClose();
            setPhotoUrl(null);
            setData({});
          }}
        >
          {getLocalisationMessage("close", "close")}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(MobileNotificationViewDialog);
