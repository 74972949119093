import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import _ from "lodash";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
} from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles } from "@material-ui/core";
import { Add, CloudDownloadOutlined, Edit, Refresh } from "@material-ui/icons";
import MUITable, { RENDER } from "../../components/orders-core/MUITable";
import TableListHeader from "../../wrappers/admin/TableListHeader";
import { getUserId, getUserWarehouse } from "../../reducers/ProfileReducer";
import { clearHybridOrders } from "../../reducers/HybridOrdersReducer";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { getPDF } from "../../helpers/FormUtils";
import {
  REPORTS_CN_38_CREATE_URL,
  REPORTS_CN_38_EDIT_URL,
} from "../../constants/AdminPathConstants";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import {
  CN_37_PDF_URL,
  CN_38_PDF_URL,
  CN_41_PDF_URL,
  getCn38List,
} from "../../api/admin/AdminIpsReportsApi";
import sprintf from "sprintf";

export const localisationCategory = (option, getLocalisationMessage) => {
  switch (option) {
    case "A":
      return "CN 38";

    case "B":
      return "CN 41";

    case "C":
      return "CN 37";

    case "D":
      return "CN 37";

    default:
      return getLocalisationMessage("na");
  }
};

export const getPdfByCategory = (
  option,
  row,
  classes,
  message,
  cn38PDF,
  setCn38PDF,
  cn37PDF,
  setCn37PDF,
  cn41PDF,
  setCn41PDF,
  pdfId,
  setPDFId,
) => {
  switch (option) {
    case "A":
      return (
        <IconButton
          className={
            _.get(cn38PDF, IS_PRINTING, false) &&
            pdfId === _.get(row, "id") &&
            classes.refresh
          }
          onClick={() => {
            setPDFId(_.get(row, "id"));
            getPDF(cn38PDF, setCn38PDF, IS_PRINTING, _.get(row, "id"), message);
          }}
        >
          {_.get(cn38PDF, IS_PRINTING, false) && pdfId === _.get(row, "id") ? (
            <Refresh />
          ) : (
            <CloudDownloadOutlined />
          )}
        </IconButton>
      );

    case "B":
      return (
        <IconButton
          className={
            _.get(cn41PDF, IS_PRINTING, false) &&
            pdfId === _.get(row, "id") &&
            classes.refresh
          }
          onClick={() => {
            setPDFId(_.get(row, "id"));
            getPDF(cn41PDF, setCn41PDF, IS_PRINTING, _.get(row, "id"), message);
          }}
        >
          {_.get(cn41PDF, IS_PRINTING, false) && pdfId === _.get(row, "id") ? (
            <Refresh />
          ) : (
            <CloudDownloadOutlined />
          )}
        </IconButton>
      );

    case "C":
      return (
        <IconButton
          className={
            _.get(cn37PDF, IS_PRINTING, false) &&
            pdfId === _.get(row, "id") &&
            classes.refresh
          }
          onClick={() => {
            setPDFId(_.get(row, "id"));
            getPDF(cn37PDF, setCn37PDF, IS_PRINTING, _.get(row, "id"), message);
          }}
        >
          {_.get(cn37PDF, IS_PRINTING, false) && pdfId === _.get(row, "id") ? (
            <Refresh />
          ) : (
            <CloudDownloadOutlined />
          )}
        </IconButton>
      );

    case "D":
      return (
        <IconButton
          className={
            _.get(cn37PDF, IS_PRINTING, false) &&
            pdfId === _.get(row, "id") &&
            classes.refresh
          }
          onClick={() => {
            setPDFId(_.get(row, "id"));
            getPDF(cn37PDF, setCn37PDF, IS_PRINTING, _.get(row, "id"), message);
          }}
        >
          {_.get(cn37PDF, IS_PRINTING, false) && pdfId === _.get(row, "id") ? (
            <Refresh />
          ) : (
            <CloudDownloadOutlined />
          )}
        </IconButton>
      );

    default:
      return "N/A";
  }
};

const enhancer = compose(
  connect(
    state => ({
      userWarehouse: getUserWarehouse(state),
      userId: getUserId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage, clearHybridOrders },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);
const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  contentRoot: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  input: { "& input": { fontSize: "20px" } },
});

const AdminCN38ReportsListContainer = props => {
  const { getLocalisationMessage, showErrorMessage: showErrorMessage1 } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState({
    refresh: false,
    page: 0,
    size: 20,
  });

  const [cn38PDF, setCn38PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_38_PDF_URL,
  });

  const [cn37PDF, setCn37PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_37_PDF_URL,
  });

  const [cn41PDF, setCn41PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_41_PDF_URL,
  });

  const [pdfId, setPDFId] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getCn38List(filter)
      .then(res => {
        setIsLoading(false);
        setList(_.get(res, "data.list"));
        setTotal(_.get(res, "data.total"));
      })
      .catch(error => {
        setIsLoading(false);
        showErrorMessage1(error);
        setList([]);
      });
  }, [filter]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });
  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });
  return (
    <AdminAppLayout
      title={getLocalisationMessage("cn_38", "CN 38")}
      className={classes.contentRoot}
    >
      <FlexBox
        style={{ backgroundColor: "white", height: "100%" }}
        direction="column"
        justify="space-between"
      >
        <TableListHeader
          buttons={
            <FlexBox
              flex={true}
              style={{ gap: 20, marginLeft: 20, marginRight: 20 }}
              align="center"
              justify="space-between"
            >
              <FlexBox
                align={ALIGN_CENTER}
                style={{ flex: "1 1 auto", gap: 16 }}
                justify="flex-end"
              >
                <FlexBox>
                  <IconButton
                    className={isLoading && classes.refresh}
                    tooltip={getLocalisationMessage("refresh")}
                    onClick={() => refreshOrderList()}
                  >
                    <Refresh style={{ color: "#1976d2" }} />
                  </IconButton>
                </FlexBox>
                <FlexBox>
                  <CustomButton
                    onClick={() => props.setLocation(REPORTS_CN_38_CREATE_URL)}
                    startIcon={<Add />}
                    variant={CONTAINED}
                    color={SECONDARY}
                    style={{ borderRadius: "20px", marginRight: 10 }}
                  >
                    {getLocalisationMessage("create", "Create ")}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          }
        />

        <MUITable
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          filter={filter}
          isLoading={isLoading}
          list={list}
          total={total}
          page={filter.page}
          rowsPerPage={filter.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          columns={[
            {
              name: "id",
              label: getLocalisationMessage("id"),
              align: "center",
              keyName: "id",
            },
            {
              type: RENDER,
              name: "barcode",
              align: "center",
              label: getLocalisationMessage("barcode"),
              render: row => (
                <FlexBox flex={true} align={ALIGN_CENTER} justify="center">
                  {_.get(row, `barcode`, "")}
                </FlexBox>
              ),
            },
            {
              type: RENDER,
              name: "departure",
              align: "center",
              label: getLocalisationMessage("departure"),
              render: row => (
                <FlexBox flex={true} align={ALIGN_CENTER} justify="center">
                  {_.get(row, `departure.name`, "")}
                </FlexBox>
              ),
            },
            {
              type: RENDER,
              name: "destination",
              align: "center",
              label: getLocalisationMessage("destination"),
              render: row => (
                <FlexBox flex={true} align={ALIGN_CENTER} justify="center">
                  {_.get(row, `destination.name`, "")}
                </FlexBox>
              ),
            },
            {
              type: RENDER,
              name: "barcode",
              align: "center",
              label: getLocalisationMessage("serial"),
              render: row => (
                <FlexBox flex={true} align={ALIGN_CENTER} justify="center">
                  {_.get(row, `serial`, "")}
                </FlexBox>
              ),
            },
            {
              type: RENDER,
              name: "category",
              align: "center",
              label: getLocalisationMessage("type"),
              render: row => (
                <FlexBox flex={true} align={ALIGN_CENTER} justify="center">
                  {localisationCategory(
                    _.get(row, `category`, ""),
                    getLocalisationMessage,
                  )}
                </FlexBox>
              ),
            },
            {
              type: RENDER,
              name: "action",
              align: "center",
              width: 330,
              label: getLocalisationMessage("action", "Action"),
              render: row => (
                <FlexBox
                  flex={true}
                  align={ALIGN_CENTER}
                  justify={JUSTIFY_CENTER}
                  style={{ gap: 16 }}
                >
                  {getPdfByCategory(
                    _.get(row, `category`, ""),
                    row,
                    classes,
                    props.showErrorMessage,
                    cn38PDF,
                    setCn38PDF,
                    cn37PDF,
                    setCn37PDF,
                    cn41PDF,
                    setCn41PDF,
                    pdfId,
                    setPDFId,
                  )}

                  <IconButton
                    onClick={() =>
                      props.setLocation(
                        sprintf(REPORTS_CN_38_EDIT_URL, _.get(row, "id")),
                      )
                    }
                  >
                    <Edit />
                  </IconButton>
                </FlexBox>
              ),
            },
          ]}
        />
      </FlexBox>
    </AdminAppLayout>
  );
};
AdminCN38ReportsListContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

export default enhancer(AdminCN38ReportsListContainer);
