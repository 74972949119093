import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import _ from "lodash";
import { safeParseDate } from "../../helpers/DateUtils";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
} from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles } from "@material-ui/core";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { Add, ArrowBackIos, DeleteForever, Refresh } from "@material-ui/icons";
import MUITable, {
  COLUMN,
  RENDER,
} from "../../components/orders-core/MUITable";
import TableListHeader from "../../wrappers/admin/TableListHeader";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import fp from "lodash/fp";
import { getUserId, getUserWarehouse } from "../../reducers/ProfileReducer";
import { DATE } from "../../components/orders-core/MUITableSimple";
import { clearHybridOrders } from "../../reducers/HybridOrdersReducer";
import BatchHistoryUpdateFilterDialog from "../../wrappers/admin/BatchHistoryUpdateFilterDialog";
import { cleanupStringArray, formatDateToUrl } from "../../helpers/FormatUtils";
import Typography from "@material-ui/core/Typography";
import {
  deleteCustomsCheckout,
  getCustomsCheckoutList,
} from "../../api/v2/admin/AdminOrderSortingApi";
import { ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL } from "../../constants/AdminPathConstants";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import CustomsCheckoutDialog from "../../wrappers/admin/CustomsCheckoutDialog";
import ScannerTextField from "../../components/deprecated/ScannerTextField";

const parseOrderNumbers = fp.flow(
  fp.trim,
  x => x.replace(/\W/g, " "),
  // fp.split(" "),
  cleanupStringArray,
);

const enhancer = compose(
  connect(
    state => ({
      userWarehouse: getUserWarehouse(state),
      userId: getUserId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage, clearHybridOrders },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);
const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  contentRoot: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  input: { "& input": { fontSize: "20px" } },
});

const AdminInboundCustomsCheckoutListContainer = props => {
  const { getLocalisationMessage } = props;
  const classes = useStyles();
  const [openCreate, setOpenCreate] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState({
    refresh: false,
    page: 0,
    size: 20,
  });

  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getCustomsCheckoutList(
      Object.fromEntries(
        // eslint-disable-next-line lodash/prefer-is-nil,no-shadow,no-unused-vars
        Object.entries(filter).filter(([_, v]) => v || v === 0),
      ),
    )
      .then(res => {
        setIsLoading(false);
        setList(_.get(res, "data.list"));
      })
      .catch(error => {
        setIsLoading(false);
        props.showErrorMessage(error);
      });
  }, [filter]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });
  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });

  const onDelete = removeId => {
    deleteCustomsCheckout(removeId)
      .then(() => {
        setDeleteId(0);
        props.showSuccessMessage(
          getLocalisationMessage(
            "successfully_removed",
            "Successfully Removed",
          ),
        );
        refreshOrderList();
      })
      .catch(error => {
        props.showErrorMessage(error);
        refreshOrderList(0);
      });
  };

  return (
    <AdminAppLayout
      slug="for_customs_checkout"
      title={getLocalisationMessage(
        "for_customs_checkout",
        "For Customs Checkout",
      )}
      className={classes.contentRoot}
    >
      <ConfirmDialog
        open={deleteId > 0}
        onRequestClose={() => {
          setDeleteId(0);
        }}
        onConfirm={() => {
          onDelete(deleteId);
          setDeleteId(0);
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_delete",
          "Are you sure you want delete?",
        )}
      </ConfirmDialog>

      <BatchHistoryUpdateFilterDialog
        initialValues={{
          ...filter,
          startDate: safeParseDate(fp.get("start_date", filter)),
          endDate: safeParseDate(fp.get("end_date", filter)),
          statuses: parseString(fp.get("statuses", filter)),
        }}
        open={isOpenFilter}
        onSubmit={v => {
          setFilter({
            ...filter,
            statuses: stringifyArray(v.statuses),
            start_date: formatDateToUrl(v.startDate),
            end_date: formatDateToUrl(v.endDate),
          });
          setIsOpenFilter(false);
        }}
        onRequestClose={() => setIsOpenFilter(false)}
      />

      <CustomsCheckoutDialog
        open={openCreate}
        onRequestClose={() => setOpenCreate(false)}
        onSubmitSuccess={() => {
          setOpenCreate(false);
          refreshOrderList();
        }}
      />

      <FlexBox
        style={{ backgroundColor: "white", height: "100%" }}
        direction="column"
        justify="space-between"
      >
        <FlexBox>
          <FlexBox align="center" style={{ width: "60%" }}>
            <Typography variant="h5" className={classes.title}>
              <IconButton
                style={{ width: 40, height: 40 }}
                onClick={() =>
                  props.setLocation(ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL)
                }
              >
                <ArrowBackIos style={{ width: 20, height: 20 }} />
              </IconButton>
            </Typography>
            <span style={{ fontSize: 16, fontWeight: "bold", marginLeft: 10 }}>
              {getLocalisationMessage(
                "for_customs_checkout",
                "For Customs Checkout",
              )}
            </span>
          </FlexBox>
          <TableListHeader
            buttons={
              <FlexBox
                align={ALIGN_CENTER}
                style={{ flex: "1 1 auto", gap: 16 }}
                justify="flex-end"
              >
                <FlexBox flex={true} style={{ width: "100%", padding: 10 }}>
                  <ScannerTextField
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    label={getLocalisationMessage("search", "Search")}
                    className={classes.input}
                    id="OrderSortingBinValidationFormScannerTextField"
                    onChange={value => {
                      const orderNumbers = value.split(",");
                      const search = parseOrderNumbers(orderNumbers[0]);

                      setFilter({ ...filter, search: search[0] });
                    }}
                  />
                </FlexBox>
                <FlexBox>
                  <CustomButton
                    size="medium"
                    startIcon={<Add />}
                    variant={CONTAINED}
                    color={SECONDARY}
                    style={{ borderRadius: "20px" }}
                    onClick={() => setOpenCreate(true)}
                  >
                    {getLocalisationMessage("create")}
                  </CustomButton>
                </FlexBox>
                <FlexBox>
                  <IconButton
                    className={isLoading && classes.refresh}
                    tooltip={getLocalisationMessage("refresh")}
                    onClick={() => refreshOrderList()}
                  >
                    <Refresh />
                  </IconButton>
                </FlexBox>
              </FlexBox>
            }
          />
        </FlexBox>

        <MUITable
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          filter={filter}
          isLoading={isLoading}
          list={list}
          total={list.length}
          page={filter.page}
          rowsPerPage={filter.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          columns={[
            {
              type: COLUMN,
              name: "id",
              label: getLocalisationMessage("id"),
              align: "center",
            },
            {
              type: RENDER,
              name: "barcode",
              label: getLocalisationMessage("barcode"),
              render: row => _.get(row, `barcode`, ""),
            },
            {
              type: DATE,
              name: "created_date",
              keyName: "created_date",
              align: "center",
              label: getLocalisationMessage("created_date"),
            },
            {
              type: RENDER,
              isSortable: false,
              name: "action",
              label: getLocalisationMessage("action", "Action"),
              align: "center",
              render: row => (
                <FlexBox
                  flex={true}
                  align={ALIGN_CENTER}
                  justify={JUSTIFY_CENTER}
                  style={{ gap: 16 }}
                >
                  <IconButton onClick={() => setDeleteId(_.get(row, "id"))}>
                    <DeleteForever />
                  </IconButton>
                </FlexBox>
              ),
            },
          ]}
        />
      </FlexBox>
    </AdminAppLayout>
  );
};
AdminInboundCustomsCheckoutListContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

export default enhancer(AdminInboundCustomsCheckoutListContainer);
