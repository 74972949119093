import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose, getContext } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { REPORTS_CN_38_URL } from "../../constants/AdminPathConstants";
import CN38Form from "../../components/orders-core/CN38Form";
import {
  CN_37_PDF_URL,
  CN_38_PDF_URL,
  CN_41_PDF_URL,
  editCn38,
  getCn38,
} from "../../api/admin/AdminIpsReportsApi";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { getValue } from "../../helpers/DataUtils";
import {
  formatDateTimeToUrl,
  formatDateToUrl,
} from "../../helpers/FormatUtils";
import { localisationCategory } from "./AdminCN38ReportsCreateContainer";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

function AdminCN38ReportsItemContainer(props) {
  const [cn38PDF, setCn38PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_38_PDF_URL,
  });

  const [cn37PDF, setCn37PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_37_PDF_URL,
  });

  const [cn41PDF, setCn41PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_41_PDF_URL,
  });

  const [data, setData] = useState({});

  useEffect(() => {
    if (props.params && props.params.id) {
      getCn38(props.params.id)
        .then(res => {
          setData(getValue(res, "data", {}));
        })
        .catch(() => {
          setData({});
        });
    }
  }, [props.params]);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <AdminAppLayout title={props.i18n.get("edit_cn_38", "Edit CN 38")}>
      <CN38Form
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setLocation={props.setLocation}
        initialValues={toCamelCase(data)}
        onSubmit={values => {
          setIsLoading(true);
          editCn38(getValue(props.params, "id"), {
            ...toSnakeCase(values),
            date: formatDateToUrl(values.date),
            dispatch_date_time: formatDateTimeToUrl(values.dispatchDateTime),
          })
            .then(res => {
              if (res && res.status === "success") {
                props.showSuccessMessage(
                  props.i18n.get("successfully_created"),
                );

                setIsLoading(false);

                localisationCategory(
                  values.category,
                  res,
                  props.showErrorMessage,
                  cn38PDF,
                  setCn38PDF,
                  cn37PDF,
                  setCn37PDF,
                  cn41PDF,
                  setCn41PDF,
                );
                props.setLocation(REPORTS_CN_38_URL);
              }
            })
            .catch(error => {
              props.showErrorMessage(error);
              setIsLoading(false);
            });
        }}
      />
    </AdminAppLayout>
  );
}

AdminCN38ReportsItemContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  showSuccessMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  params: PropTypes.object,
};

export default enhancer(AdminCN38ReportsItemContainer);
