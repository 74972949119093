import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const MailClassAutocomplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(({ getById, input: { value } }) =>
      value && value.id && !value.name && getById
        ? getById(value.id)
            .map(item => ({ id: value.id, name: item.get("name") }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value.name"))
    .switchMap(({ chooseAPI, input: { value } }) =>
      chooseAPI
        ? chooseAPI(baseFilter.setSearch(value && value.name))
            .map(fp.flow(fp.get("payload.data.list"), fp.toArray, fromJS))
            .catch(() => Observable.of(List()))
        : Observable.of(List()),
    )
    .distinctUntilChanged(isEqualData)
    .map(v => v.toJS());

  return propsStream.combineLatest(
    valueStream,
    optionsStream,
    ({ readOnly, getById, chooseAPI, ...props }, value, options) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={formatOption}
        input={{ ...props.input, value }}
      />
    ),
  );
});

FormMailClassAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,
  levelId: PropTypes.string,
  parentId: PropTypes.string,
};

FormMailClassAutocomplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormMailClassAutocomplete(props) {
  return <Field {...props} component={MailClassAutocomplete} />;
}
