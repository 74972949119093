import React from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import ModalPaper from "../../ui-core/ModalPaper";
import { toSnakeCase } from "../../../helpers/CaseMapper";
import FlightSettingsForm from "./FlightSettingsForm";

const enhancer = compose(
  useSheet({
    paper: { maxWidth: "560px", minWidth: "560px", minHeight: 100 },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
);

CreateFlightsDialog.propTypes = {
  classes: PropTypes.object,

  onRequestClose: PropTypes.func.isRequired,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  createApi: PropTypes.func,
  refreshList: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
};

function CreateFlightsDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={props.open}
      title={props.title}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <FlightSettingsForm
        onSubmit={values => {
          props
            .createApi(toSnakeCase(values))
            .then(res => {
              if (res && res.status === "success") {
                props.onRequestClose();
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_created",
                    "Successfully Created",
                  ),
                );
                props.refreshList();
              }
            })
            .catch(error => props.showErrorMessage(error));
        }}
        onDismiss={props.onRequestClose}
      />
    </ModalPaper>
  );
}

export default enhancer(CreateFlightsDialog);
