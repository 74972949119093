import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pureComponent, renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getCachedTag, getTagPredictions } from "../../api/admin/AdminTagApi";
import {
  getCachedOwnerUser,
  getCachedSalesUser,
  getOwnerUserPredictions,
  getSalesUserPredictions,
} from "../../api/admin/AdminUserApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedTimeSlot,
  getTimeSlotPredictions,
} from "../../api/admin/AdminTimeSlotApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import { getSaaSMarketplacesList } from "../../api/shared/MarketplaceApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import ArchiveOrderFilterForm from "../../components/orders-core/ArchiveOrderFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withState("state", "setState", {
    toggleContainer: null,
    filterNameContainer: null,
  }),
  useSheet({
    paper: {
      "@media (max-width: 998px)": {
        minWidth: "300px",
      },
    },
  }),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

AdminArchiveOrderFilterWrapper.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  showFinanceMarketplace: PropTypes.bool,
  setFilter: PropTypes.func,
  closeDialog: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  setSelectedItems: PropTypes.func,
  showCashier: PropTypes.bool,
  isCustomFilter: PropTypes.bool,
  showSearch: PropTypes.bool,
  showLogistic: PropTypes.bool,
};

AdminArchiveOrderFilterWrapper.defaultProps = {
  showCashier: false,
  showFinanceMarketplace: false,
  showSearch: false,
  showLogistic: false,
};

function AdminArchiveOrderFilterWrapper({
  classes,
  filter,
  getLocalisationMessage,
  isCustomFilter,
  onFilterChange,
  onRequestClose,
  open,
  setState,
  showCashier,
  showFinanceMarketplace,
  showLogistic,
  showSearch,
  state,
  setFilter,
  closeDialog,
  setSelectedItems,
}) {
  return (
    <ModalPaper
      open={open}
      paperClassName={classes.paper}
      title={
        <FlexBox flex={true}>
          <FlexBox>{getLocalisationMessage("order_filter")}</FlexBox>

          {isCustomFilter && (
            <FlexBox flex={true} justify="center">
              <div ref={x => setState(fp.set("filterNameContainer", x))} />
            </FlexBox>
          )}
        </FlexBox>
      }
      onRequestClose={onRequestClose}
      rightIcon={<div ref={x => setState(fp.set("toggleContainer", x))} />}
    >
      <FlexBox container={8} direction="column">
        <ArchiveOrderFilterForm
          filter={filter}
          setSelectedItems={setSelectedItems}
          setFilter={setFilter}
          closeDialog={closeDialog}
          showLogistic={showLogistic}
          showAddressType={true}
          showCompany={true}
          showMerchant={true}
          showAttemptCounts={true}
          showCashier={showCashier}
          showSearch={showSearch}
          showFinanceMarketplace={showFinanceMarketplace}
          showParcelHeavyTypes={true}
          showPromiseDate={true}
          showMarketplace={false}
          onDismiss={onRequestClose}
          onFilterChange={onFilterChange}
          toggleContainer={state.toggleContainer}
          filterNameContainer={state.filterNameContainer}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedOwnerUser={getCachedOwnerUser}
          getOwnerUserPredictions={getOwnerUserPredictions}
          getCachedSalesUser={getCachedSalesUser}
          getSalesUserPredictions={getSalesUserPredictions}
          getCachedTimeSlot={getCachedTimeSlot}
          getTimeSlotPredictions={getTimeSlotPredictions}
          getMarketplace={getSaaSMarketplacesList}
          getCachedServiceType={getCachedServiceType}
          getServiceTypePredictions={getServiceTypePredictions}
          getCachedTag={getCachedTag}
          getTagPredictions={getTagPredictions}
          getCachedCity={getCachedCity}
          getCityPredictions={getCityPredictions}
          getCachedCountry={getCachedCountry}
          getCountryPredictions={getCountryPredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminArchiveOrderFilterWrapper);
