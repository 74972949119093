import React from "react";
import _ from "lodash";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, FieldArray, formValueSelector } from "redux-form";
import { Card, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import MarketplaceSettingsFormLeaf from "./MarketplaceSettingsFormLeaf";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED } from "../ui-core/CustomButton";
import { SECONDARY } from "../form/FormTextField";

const FORM = "MarketplaceSettingsForm";
const valueSelector = formValueSelector(FORM);

const SettingsFields = props => (
  <FlexBox flex={true} direction="column">
    <FlexBox flex={true} direction="column">
      {props.fields.map((settings, index) => (
        <FlexBox gutter={8} flex={true} key={index}>
          <Card className={props.classes.card}>
            <MarketplaceSettingsFormLeaf
              className={props.classes.marketplaceSettingsFormLeaf}
              settingsOptions={props.settingsOptions}
              name={settings}
              // onRemove={() => {props.fields.remove(index);props.onRemove(index);}}
            />
          </Card>
        </FlexBox>
      ))}
    </FlexBox>
  </FlexBox>
);

SettingsFields.propTypes = {
  fields: PropTypes.object,
  settingsOptions: PropTypes.instanceOf(List),
  classes: PropTypes.object,
  // onRemove: PropTypes.func,
};

const enhancer = compose(
  useSheet({
    subheader: { flex: "1 1 0%", paddingLeft: "0px" },
    padding: { paddingTop: 10 },
    card: {
      width: "100%",
      marginBottom: "10px",
      marginTop: "10px",
      paddingLeft: "20px",
      paddingRight: "20px",
      minHeight: "60px",
      "& > div": {
        paddingTop: "6px",
      },
    },
    root: { position: "relative" },
    addBtn: {
      marginTop: "4px",
    },
  }),
  withContext(
    {
      marketplaceId: PropTypes.number,
    },
    props => ({
      marketplaceId: props.marketplaceId,
    }),
  ),
  connect(state => ({
    settingsOption: valueSelector(state, "settingsOption"),
    leafValues: valueSelector(state, "settings"),
    settingSelectOptions: valueSelector(state, "settingSelectOptions"),
    i18n: getMessages(state),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(fp.pick(["settings", "item"]))
      .distinctUntilChanged(isEqualData)
      .filter(props => props.settings.size > 0)
      .distinctUntilChanged(isEqualData)
      .map(props => {
        const initialSettings = [];
        const settingSelectOptions = List().asMutable();
        const marketplaceSettings = props.item.get("settings", Map());

        props.settings.forEach(item => {
          const shortName = item.get("short_name");

          // if (marketplaceSettings.has(shortName)) {
          const dataType = item.get("data_type");

          switch (dataType) {
            case "Object":
              initialSettings.push({
                field: item,
                [shortName]: _.isObject(marketplaceSettings.get(shortName))
                  ? marketplaceSettings.get(shortName).toJS()
                  : {},
              });
              break;
            default:
              initialSettings.push({
                field: item,
                [shortName]: marketplaceSettings.get(shortName),
              });
          }
          // } else settingSelectOptions.push(item);
        });

        return {
          settingSelectOptions,
          settings: initialSettings,
        };
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  withHandlers({
    addSettingsRow: props => change => {
      if (props.settingsOption) {
        const { leafValues } = props;
        const settings = leafValues || [];
        const settingOptions = List().asMutable();
        settings.push({ field: props.settingsOption });

        props.settings.forEach(item => {
          const filter = _.filter(settings, c => {
            const field = getValue(c, "field");
            return field && field.get("key") === item.get("key");
          });

          if (filter.length === 0) settingOptions.push(item);
        });

        change("settingSelectOptions", settingOptions.asImmutable());
        change("settingsOption", null);
        change("settings", settings);
      }
    },
    removeSettingsRow: props => (change, index) => {
      const { leafValues } = props;
      const settings = leafValues || [];
      const settingOptions = List().asMutable();
      settings.splice(index, 1);

      props.settings.forEach(item => {
        const filter = _.filter(settings, c => {
          const field = getValue(c, "field");
          return field && field.get("key") === item.get("key");
        });

        if (filter.length === 0) settingOptions.push(item);
      });

      change("settingSelectOptions", settingOptions.asImmutable());
      change("settings", settings);
    },
    onSubmit: props => values => {
      const marketplaceSettings = props.item.get("settings", Map());
      const settings = Map().asMutable();

      values.settings.forEach(item => {
        const field = getValue(item, "field");
        settings.set(
          field.get("short_name"),
          getValue(item, field.get("short_name")),
        );
      });

      if (marketplaceSettings.has("logo")) {
        settings.set("logo", marketplaceSettings.get("logo"));
      }

      if (marketplaceSettings.has("login_logo")) {
        settings.set("login_logo", marketplaceSettings.get("login_logo"));
      }

      if (marketplaceSettings.has("favicon")) {
        settings.set("favicon", marketplaceSettings.get("favicon"));
      }

      props.onSubmit(settings.asImmutable().toJS());
    },
  }),
  reduxForm({
    form: FORM,
    enableReinitialize: true,
  }),
);

MarketplaceSettingsForm.propTypes = {
  classes: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  settings: PropTypes.instanceOf(List),
  i18n: PropTypes.instanceOf(Map),
};

function MarketplaceSettingsForm(props) {
  const { i18n } = props;
  return (
    <div>
      <PageLoading isLoading={props.submitting} />

      <FlexBox gutter={8} container={8} direction="column">
        <FieldArray
          name="settings"
          // onRemove={index => props.removeSettingsRow(props.change, index)}
          props={{
            ...props,
            classes: props.classes,
            settingsOptions: props.settings,
          }}
          component={SettingsFields}
        />
      </FlexBox>

      <FlexBox justify="flex-end">
        <CardActions>
          {props.dirty ? (
            <Button onClick={props.reset}>{i18n.get("reset", "Reset")}</Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {i18n.get("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            fullWidth={true}
            onClick={props.handleSubmit}
          >
            {i18n.get("save", "save")}
          </CustomButton>
        </CardActions>
      </FlexBox>
    </div>
  );
}

export default enhancer(MarketplaceSettingsForm);
