import React from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminMarketplaceDetailsAllSettingsContainer from "./AdminMarketplaceDetailsAllSettingsContainer";
import { getMarketplaceId } from "../../../reducers/MarketplaceReducer";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";

const enhancer = compose(
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  connect(
    state => ({
      // values: getValues(state),
      marketplaceId: getMarketplaceId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminMarketplaceDetailsSettingsContainer.propTypes = {
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminMarketplaceDetailsSettingsContainer(props) {
  const { getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={getLocalisationMessage("company_setting", "Company Settings")}
    >
      <AdminMarketplaceDetailsAllSettingsContainer />
    </AdminAppLayout>
  );
}

export default enhancer(AdminMarketplaceDetailsSettingsContainer);
