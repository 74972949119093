import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, {
  CONTAINED,
  SECONDARY,
  OUTLINED,
} from "../ui-core/CustomButton";
import { Card, CardActions, CardContent } from "@material-ui/core";
import FormServiceTypeByIdAutocomplete from "../form/FormServiceTypeByIdAutocomplete";
import FormJMAutoComplete from "../form/FormJMAutoComplete";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "OrderFlowOperationFilterForm",
    enableReinitialize: true,
  }),
);

OrderFlowOperationFilterForm.propTypes = {
  getLocalisationMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

function OrderFlowOperationFilterForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <FlexBox
      container={16}
      style={{ gap: 14 }}
      flex={true}
      element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
    >
      <Card style={{ width: "100%" }}>
        <CardContent>
          <FlexBox flex={true} direction="column" style={{ gap: 14 }}>
            <FlexBox flex={true} style={{ gap: 14 }}>
              <FlexBox flex={true}>
                <FormServiceTypeByIdAutocomplete
                  fullWidth={true}
                  optional={true}
                  name="serviceType"
                  label={getLocalisationMessage("service_type", "Service Type")}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true}>
              <FormJMAutoComplete
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                fullWidth={true}
                name="fromJurisdiction"
                label={getLocalisationMessage(
                  "from_jurisdiction",
                  "From Jurisdiction",
                )}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <FormJMAutoComplete
                renderOption={option => (
                  <FlexBox direction="column">
                    <span>{option.name}</span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {option.value.hierarchy.map((item, index, arr) =>
                        index === arr.length - 1
                          ? item.name
                          : `${item.name} > `,
                      )}
                    </span>
                  </FlexBox>
                )}
                fullWidth={true}
                name="toJurisdiction"
                label={getLocalisationMessage(
                  "to_jurisdiction",
                  "To Jurisdiction",
                )}
              />
            </FlexBox>
          </FlexBox>
        </CardContent>

        <FlexBox justify="flex-end">
          <CardActions>
            <FlexBox justify="flex-end" style={{ gap: 14 }}>
              <CustomButton
                variant={OUTLINED}
                color={SECONDARY}
                onClick={() => {
                  props.onCancel();
                }}
              >
                {getLocalisationMessage("cancel", "Cancel")}
              </CustomButton>
              <CustomButton variant={CONTAINED} color={SECONDARY} type="submit">
                {getLocalisationMessage("submit", "Submit")}
              </CustomButton>
            </FlexBox>
          </CardActions>
        </FlexBox>
      </Card>
    </FlexBox>
  );
}

export default enhancer(OrderFlowOperationFilterForm);
