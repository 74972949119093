import React, { useEffect, useState } from "react";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  CardActions,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../../ui-core/FlexBox";
import PageLoading from "../../ui-core/PageLoading";
import { getMessage } from "../../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../../ui-core/CustomButton";
import { Add } from "@material-ui/icons";
import FormTextField from "../../form/FormTextField";
import { isShallowEqual } from "../../../helpers/DataUtils";
import FormSelectField from "../../form/FormSelectField";
import { formatText } from "../../../helpers/FormatUtils";
import { validateString } from "../../../helpers/ValidateUtils";
import imgNotFound from "../../order-details-dialog/assets/notfound.png";
import { getFileUrl2 } from "../../../api/shared/FileApi";
import FormFileUploadFieldWithData from "../../form/FormFileUploadFieldWithData";
import EditorField from "../../html-editor-core/EditorField";

const options = ["UZPOST", "POSTMAN"];

export const localisation = (option, getLocalisationMessage) => {
  switch (option) {
    case "UZPOST":
      return getLocalisationMessage("customer_app", "Customer app");

    case "POSTMAN":
      return getLocalisationMessage("postman_app", "Postman app");

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

const useStyles = makeStyles({
  imageContainer: {
    minHeight: "350px",
    textAlign: "center",
    marginBottom: "6px",
    "& > img": { maxWidth: "550px", maxHeight: "550px" },
  },
  editorRoot: {
    "& .jodit-status-bar": {
      display: "none",
    },
  },
});

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const handleSubmit = props => values => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit(values);
    };

    return propsStream
      .combineLatest(props => ({
        ...props,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isShallowEqual);
  }),
  reduxForm({
    form: "MobileNotificationSettingsForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      title: validateString(
        values.title,
        props.getLocalisationMessage("this_field_is_required"),
      ),
      message: validateString(
        values.message,
        props.getLocalisationMessage("this_field_is_required"),
      ),
      appType:
        !values.appType &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  formValues({
    photo: "photo",
  }),
);

MobileNotificationSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  submitting: PropTypes.bool,
  edit: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  photo: PropTypes.object,
};

function MobileNotificationSettingsForm(props) {
  const { getLocalisationMessage, photo } = props;

  const [photoUrl, setPhotoUrl] = useState("");

  useEffect(() => {
    if (photo && photo.id) {
      getFileUrl2(photo.id)
        .then(res => setPhotoUrl(res.data))
        .catch(() => setPhotoUrl(null));
    }
  }, [photo]);

  const classes = useStyles();

  return (
    <FlexBox
      flex={true}
      element={<form onSubmit={props.handleSubmit} />}
      style={{ height: "100%" }}
    >
      <FlexBox flex={true} direction="column">
        <Card style={{ height: "100%" }}>
          <FlexBox flex={true} element={<CardContent />}>
            <FlexBox flex={true} gutter={16} direction="column">
              <PageLoading isLoading={props.submitting} />

              <FlexBox flex={true}>
                <FormTextField
                  name="title"
                  fullWidth={true}
                  label={`${getLocalisationMessage("title", "Title")} *`}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormSelectField
                  options={options}
                  name="appType"
                  formatOption={option =>
                    localisation(option, getLocalisationMessage)
                  }
                  fullWidth={true}
                  label={`${getLocalisationMessage("app", "App")} *`}
                />
              </FlexBox>
              <FlexBox flex={true} className={classes.editorRoot}>
                <EditorField
                  name="message"
                  fullWidth={true}
                  label={`${getLocalisationMessage("message", "Message")} *`}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox
              style={{ height: 550 }}
              flex={true}
              direction="column"
              align="center"
              justify="space-between"
            >
              <ListSubheader>
                {getLocalisationMessage("photo", "Photo")}
              </ListSubheader>
              <div className={classes.imageContainer}>
                <img
                  alt={getLocalisationMessage("photo", "Photo")}
                  src={photoUrl || imgNotFound}
                />
              </div>

              <FormFileUploadFieldWithData
                fullWidth={true}
                name="photo"
                label={getLocalisationMessage("upload", "UPLOAD")}
                accept="image/*"
              />
            </FlexBox>
          </FlexBox>

          <FlexBox
            element={
              <CardActions
                style={{ position: "fixed", bottom: 20, right: 20 }}
              />
            }
            justify="flex-end"
            align="flex-end"
          >
            <Button onClick={props.onDismiss}>
              {getLocalisationMessage("cancel", "Cancel")}
            </Button>

            <CustomButton
              startIcon={<Add />}
              variant={CONTAINED}
              type="submit"
              color={SECONDARY}
            >
              {props.edit
                ? getLocalisationMessage("update", "Update")
                : getLocalisationMessage("create", "Create")}
            </CustomButton>
          </FlexBox>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(MobileNotificationSettingsForm);
