import React, { useEffect, useRef, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PageLoading from "../../ui-core/PageLoading";
import FlexBox, { JUSTIFY_END } from "../../ui-core/FlexBox";
import FormTextField, { OUTLINED, SECONDARY } from "../../form/FormTextField";
import CustomButton, { CONTAINED } from "../../ui-core/CustomButton";
import FormCheckbox from "../../form/FormCheckbox";
import FormDateField from "../../form/FormDateField";
import { formatDate } from "../../../helpers/FormatUtils";
import FormAutocompleteNew from "../../form/FormInternationalSettingsAutocomplete";
import {
  getCacheRegion,
  getCacheRegionCode,
  getFormatCache,
  getInternationalFormatChoose,
  getInternationalRegionChoose,
  getInternationalSubclassesChoose,
  getSubclassesCache,
} from "../../../api/admin/AdminInternationalReportsApi";
import { getValue } from "../../../helpers/DataUtils";
import fp from "lodash/fp";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  localisationAction,
  localisationCategory,
} from "./InternationalParcelForm";
import { isValidDate, isValidObjectId } from "../../../helpers/ValidateUtils";
import FormAutoComplete from "../../form/FormAutoComplete";

const actions = ["OUT", "IN"];
const category = ["AIR", "GROUND", "SAL"];

const getValues = getFormValues("InternationalLettersForm");

const cleanupValues = fp.omit([
  "action",
  "category",
  "fromCountry",
  "fromCity",
  "toCountry",
  "toCity",
  "transitRegion",
  "date",
  "format",
  "formationDate",
]);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "InternationalLettersForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      action:
        !values.action &&
        props.getLocalisationMessage("this_field_is_required"),
      category:
        !values.category &&
        props.getLocalisationMessage("this_field_is_required"),
      date:
        !isValidDate(values.date) &&
        props.getLocalisationMessage("this_field_is_required"),
      dispatchNumber:
        !values.dispatchNumber &&
        props.getLocalisationMessage("this_field_is_required"),
      weight:
        !values.hasMbags &&
        !(values.weight > 0) &&
        props.getLocalisationMessage("this_field_is_required"),
      mbagWeight:
        values.hasMbags &&
        !(values.mbagWeight > 0) &&
        props.getLocalisationMessage("this_field_is_required"),
      subclass:
        !isValidObjectId(values.subclass) &&
        props.getLocalisationMessage("this_field_is_required"),
      fromCountry:
        !isValidObjectId(values.fromCountry) &&
        props.getLocalisationMessage("this_field_is_required"),
      fromCity:
        !isValidObjectId(values.fromCity) &&
        props.getLocalisationMessage("this_field_is_required"),
      toCountry:
        !isValidObjectId(values.toCountry) &&
        props.getLocalisationMessage("this_field_is_required"),
      toCity:
        !isValidObjectId(values.toCity) &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
        fromCountry: values.fromCountry,
        toCountry: values.toCountry,
      },
    })),
  ),
);

const InternationalLettersForm = ({
  getLocalisationMessage,
  handleSubmit,
  submitting,
  values,
  change,
  onClose,
  success,
  update,
  setSuccess,
  reset,
}) => {
  const [ignore, setIgnore] = useState(true);
  const [clear103, setClear103] = useState(false);
  const [clearAdd, setClearAdd] = useState(false);

  useEffect(() => {
    const ignoreValues = cleanupValues(values);
    if (success) {
      if (clear103) {
        fp.keys(ignoreValues).forEach(key => {
          change(key, null);
        });
        setSuccess(false);
        setClear103(false);
        setIgnore(false);
      } else if (clearAdd) {
        reset();
        setClearAdd(false);
        setSuccess(false);
        setIgnore(false);
      }
    }
  }, [success]);

  const { fromCountry, toCountry, action } = values;

  const actionRef = useRef(null);
  const categoryRef = useRef(null);
  const fromCountryRef = useRef(null);
  const fromCityRef = useRef(null);
  const toCountryRef = useRef(null);
  const toCityRef = useRef(null);
  const transitCountryRef = useRef(null);
  const formatDateRef = useRef(null);
  const dateRef = useRef(null);
  const dispatchNumberRef = useRef(null);
  const formatRef = useRef(null);
  const subclassRef = useRef(null);
  const weightRef = useRef(null);
  const registeredRef = useRef(null);
  const valuedRef = useRef(null);
  const itemsRef = useRef(null);
  const commentsRef = useRef(null);
  const mBagsRef = useRef(null);
  const mBagsWeightRef = useRef(null);
  const mBagsItemRef = useRef(null);
  const mbagItemsMoreThan5KgRef = useRef(null);
  const submitButtonRef = useRef(null);

  const handleKeyDown = (e, ref, dateClick) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (dateClick) {
        ref.current.click();
        ref.current.focus();
      } else {
        ref.current.focus();
      }
    }
  };

  const handleSelectChange = (ref, dateClick) => {
    if (dateClick) {
      ref.current.click();
      ref.current.focus();
    } else {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (!update) {
      if (action === "OUT") {
        getCacheRegionCode("UZB").then(r => {
          change("fromCountry", getValue(r, "data", {}));
          change("fromCity", null);
          change("toCountry", null);
          change("toCity", null);
        });
      } else if (action === "IN") {
        getCacheRegionCode("UZB").then(r => {
          change("toCountry", getValue(r, "data", {}));
          change("fromCountry", null);
          change("fromCity", null);
          change("toCity", null);
        });
      }
    }
  }, [action]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <PageLoading isLoading={submitting} />
      <FlexBox container={8} direction="column" style={{ gap: 13 }}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormAutoComplete
              ignoreError={ignore && !success}
              name="action"
              fullWidth={true}
              options={actions}
              formatOption={option =>
                localisationAction(option, getLocalisationMessage)
              }
              label={`${getLocalisationMessage("action", "Action")}*`}
              onChange={() => {
                change("tariff", null);
              }}
              onKeyDown={e => handleKeyDown(e, categoryRef)}
              inputRef={actionRef}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormAutoComplete
              ignoreError={ignore && !success}
              name="category"
              fullWidth={true}
              options={category}
              formatOption={option =>
                localisationCategory(option, getLocalisationMessage)
              }
              label={`${getLocalisationMessage("category", "Category")}*`}
              onChange={() => {
                change("tariff", null);
              }}
              onKeyDown={e => handleKeyDown(e, fromCountryRef)}
              inputRef={categoryRef}
            />
          </FlexBox>

          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              ignoreError={ignore && !success}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              name="fromCountry"
              levelId={1}
              type="CN55"
              fullWidth={true}
              label={`${getLocalisationMessage(
                "from_country",
                "From Country",
              )} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              onChange={() => {
                change("fromCity", null);
              }}
              inputRef={fromCountryRef}
              onKeyDown={e => handleKeyDown(e, fromCityRef)}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
            />
          </FlexBox>

          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              ignoreError={ignore && !success}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              name="fromCity"
              type="CN55"
              inputRef={fromCityRef}
              onKeyDown={e => handleKeyDown(e, toCountryRef)}
              disabled={!getValue(fromCountry, "id")}
              levelId={2}
              parentId={getValue(fromCountry, "id")}
              fullWidth={true}
              label={`${getLocalisationMessage("from_city", "From City")} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
            />
          </FlexBox>

          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              inputRef={toCountryRef}
              onKeyDown={e => handleKeyDown(e, toCityRef)}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              name="toCountry"
              levelId={1}
              type="CN55"
              fullWidth={true}
              ignoreError={ignore && !success}
              label={`${getLocalisationMessage("to_country", "To Country")} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              onChange={() => {
                change("toCity", null);
              }}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
            />
          </FlexBox>

          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormAutocompleteNew
              inputRef={toCityRef}
              onKeyDown={e => handleKeyDown(e, transitCountryRef)}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              name="toCity"
              type="CN55"
              fullWidth={true}
              disabled={!getValue(toCountry, "id")}
              levelId={2}
              parentId={getValue(toCountry, "id")}
              ignoreError={ignore && !success}
              label={`${getLocalisationMessage("to_city", "To City")} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                </FlexBox>
              )}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormAutocompleteNew
              inputRef={transitCountryRef}
              onKeyDown={e => handleKeyDown(e, dateRef, true)}
              getById={getCacheRegion}
              chooseAPI={getInternationalRegionChoose}
              type="CN55"
              renderOption={option => (
                <FlexBox direction="column">
                  <span>
                    {getValue(option, "value.code", " ")
                      ? `${getValue(option, "value.name", " ")}(${getValue(
                          option,
                          "value.code",
                          " ",
                        )})`
                      : getValue(option, "value.name", " ")}
                  </span>
                  {getValue(option, "value.parent.id") && (
                    <span
                      style={{
                        fontSize: ".8rem",
                        fontStyle: "italic",
                      }}
                    >
                      {getValue(option, "value.parent.code")
                        ? `${getValue(option, "value.parent.name")} (${getValue(
                            option,
                            "value.parent.code",
                            " ",
                          )})`
                        : getValue(option, "value.parent.name")}
                    </span>
                  )}
                </FlexBox>
              )}
              name="transitRegion"
              fullWidth={true}
              label={getLocalisationMessage("through", "Through")}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormDateField
              inputRef={formatDateRef}
              onKeyDown={e => handleKeyDown(e, dateRef)}
              handleSelectChange={() => handleSelectChange(dateRef)}
              name="formationDate"
              fullWidth={true}
              formatValue="dd-MM-yyyy"
              formatDate={date => formatDate(date, "dd-MM-yyyy")}
              hintText={getLocalisationMessage(
                "formation_date",
                "Formation Date",
              )}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormDateField
              inputRef={dateRef}
              onKeyDown={e => handleKeyDown(e, dispatchNumberRef)}
              handleSelectChange={() => handleSelectChange(dispatchNumberRef)}
              name="date"
              fullWidth={true}
              formatValue="dd-MM-yyyy"
              formatDate={date => formatDate(date, "dd-MM-yyyy")}
              hintText={`${getLocalisationMessage("date", "Date")} *`}
              ignoreError={ignore}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              inputRef={dispatchNumberRef}
              onKeyDown={e => handleKeyDown(e, formatRef)}
              name="dispatchNumber"
              fullWidth={true}
              label={`${getLocalisationMessage(
                "dispatch_number",
                "Dispatch Number",
              )} *`}
              ignoreError={ignore}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormAutocompleteNew
              inputRef={formatRef}
              onKeyDown={e => handleKeyDown(e, subclassRef)}
              chooseData="data"
              getById={getFormatCache}
              chooseAPI={getInternationalFormatChoose}
              name="format"
              fullWidth={true}
              label={getLocalisationMessage("format", "Format")}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormAutocompleteNew
              inputRef={subclassRef}
              onKeyDown={e => handleKeyDown(e, weightRef)}
              chooseData="data"
              getById={getSubclassesCache}
              chooseAPI={getInternationalSubclassesChoose}
              name="subclass"
              fullWidth={true}
              label={`${getLocalisationMessage("subclass", "Subclass")} *`}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
              ignoreError={ignore}
              onChange={option => {
                if (option && option.name === "MM") {
                  change("hasMbags", true);
                  change("weight", null);
                  change("unRegistered", null);
                  change("valued", null);
                  change("registered", null);
                } else {
                  change("hasMbags", false);
                }
              }}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              inputRef={weightRef}
              onKeyDown={e => handleKeyDown(e, registeredRef)}
              name="weight"
              disabled={values.hasMbags}
              type="number"
              fullWidth={true}
              label={
                values.hasMbags
                  ? getLocalisationMessage("weight", "Weight")
                  : `${getLocalisationMessage("weight", "Weight")} *`
              }
              ignoreError={ignore}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              inputRef={registeredRef}
              onKeyDown={e => handleKeyDown(e, valuedRef)}
              name="registered"
              fullWidth={true}
              type="number"
              disabled={values.hasMbags}
              label={getLocalisationMessage("registered", "Registered")}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              inputRef={valuedRef}
              onKeyDown={e => handleKeyDown(e, itemsRef)}
              name="valued"
              type="number"
              fullWidth={true}
              disabled={values.hasMbags}
              label={getLocalisationMessage("valued", "Valued")}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              inputRef={itemsRef}
              onKeyDown={e => handleKeyDown(e, commentsRef)}
              name="unRegistered"
              type="number"
              disabled={values.hasMbags}
              fullWidth={true}
              label={getLocalisationMessage("tracked", "Tracked")}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormTextField
              inputRef={commentsRef}
              onKeyDown={e => handleKeyDown(e, mBagsRef)}
              name="comments"
              fullWidth={true}
              label={getLocalisationMessage("comments", "Comments")}
            />
          </FlexBox>
          <FlexBox flex={true} align="center" justify="center">
            <FormCheckbox
              inputRef={mBagsRef}
              onKeyDown={e =>
                handleKeyDown(
                  e,
                  values.hasMbags ? mBagsWeightRef : submitButtonRef,
                )
              }
              name="hasMbags"
              label={`${getLocalisationMessage(
                "in_dispatch_has_m_bags",
                "In dispatch has M bags",
              )}`}
              onChange={() => {
                change("weight", null);
                change("unRegistered", null);
                change("valued", null);
                change("registered", null);
              }}
            />
          </FlexBox>
          {values.hasMbags ? (
            <FlexBox flex={true}>
              <FormTextField
                inputRef={mBagsWeightRef}
                onKeyDown={e => handleKeyDown(e, mBagsItemRef)}
                name="mbagWeight"
                fullWidth={true}
                type="number"
                label={`${getLocalisationMessage(
                  "m_bag_weight",
                  "M bag weight",
                )} *`}
              />
            </FlexBox>
          ) : (
            <FlexBox flex={true} />
          )}
          {values.hasMbags ? (
            <FlexBox flex={true}>
              <FormTextField
                inputRef={mBagsItemRef}
                onKeyDown={e => handleKeyDown(e, mbagItemsMoreThan5KgRef)}
                name="mbagItems"
                type="number"
                fullWidth={true}
                label={getLocalisationMessage("items", "Items")}
              />
            </FlexBox>
          ) : (
            <FlexBox flex={true} />
          )}
          {values.hasMbags ? (
            <FlexBox flex={true}>
              <FormTextField
                inputRef={mbagItemsMoreThan5KgRef}
                onKeyDown={e => handleKeyDown(e, submitButtonRef)}
                name="mbagItemsMoreThan5Kg"
                type="number"
                fullWidth={true}
                label={getLocalisationMessage(
                  "weight_m_5_kg",
                  "Weight M > 5kg",
                )}
              />
            </FlexBox>
          ) : (
            <FlexBox flex={true} />
          )}

          <FlexBox flex={true} />

          <FlexBox
            flex={true}
            justify={JUSTIFY_END}
            align="center"
            style={{ gap: 16 }}
          >
            {!update && (
              <CustomButton
                variant={CONTAINED}
                color={SECONDARY}
                buttonRef={submitButtonRef}
                fullWidth={true}
                type="submit"
                onClick={() => {
                  setIgnore(true);
                  setClear103(true);
                  handleSelectChange(actionRef);
                }}
              >
                {getLocalisationMessage("continue", "Continue")}
              </CustomButton>
            )}
          </FlexBox>

          <FlexBox
            flex={true}
            justify="space-between"
            align="center"
            style={{ gap: 16 }}
          >
            <CustomButton
              onClick={() => {
                setSuccess(true);
                setIgnore(true);
                setClearAdd(true);
                onClose();
              }}
              fullWidth={true}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("discard", "Discard")}
            </CustomButton>

            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              fullWidth={true}
              type="submit"
              onClick={() => {
                setIgnore(true);
                setClearAdd(true);
              }}
            >
              {update
                ? getLocalisationMessage("update", "update")
                : getLocalisationMessage("add", "Add")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
};
InternationalLettersForm.propTypes = {
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  update: PropTypes.bool,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
  onClose: PropTypes.func,
  setSuccess: PropTypes.func,
  reset: PropTypes.func,
};
export default enhancer(InternationalLettersForm);
