import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { getMessages } from "../../reducers/LocalizationReducer";
import { Map } from "immutable";
import CustomsCheckForm from "../../components/orders-core/CustomsCheckForm";
import { createCustomsCheckout } from "../../api/v2/admin/AdminOrderSortingApi";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

CustomsCheckoutDialog.propTypes = {
  open: PropTypes.bool,
  disableBarcodes: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  warehouseId: PropTypes.number,
  i18n: PropTypes.instanceOf(Map),
};

function CustomsCheckoutDialog({ initialValues, ...props }) {
  return (
    <CustomsCheckForm
      open={props.open}
      onRequestClose={props.onRequestClose}
      onSubmit={fp.flow(toSnakeCase, values =>
        createCustomsCheckout(values).catch(ResponseError.throw),
      )}
      onSubmitFail={response => {
        props.showErrorMessage(response);
      }}
      onSubmitSuccess={response => {
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess(response);
      }}
    />
  );
}

export default enhancer(CustomsCheckoutDialog);
