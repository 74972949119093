import React from "react";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../../ui-core/FlexBox";
import PageLoading from "../../ui-core/PageLoading";
import { getMessage } from "../../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../../ui-core/CustomButton";
import { Add } from "@material-ui/icons";
import FormTextField from "../../form/FormTextField";
import { isShallowEqual } from "../../../helpers/DataUtils";
import FormSelectField from "../../form/FormSelectField";

const options = ["CN38", "CN55"];

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const handleSubmit = props => values => {
      if (!props.onSubmit) {
        return null;
      }
      return props.onSubmit(values);
    };

    return propsStream
      .combineLatest(props => ({
        ...props,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isShallowEqual);
  }),
  reduxForm({
    form: "NewRegionSettingsForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name: !values.name
        ? props.getLocalisationMessage("this_field_is_required")
        : null,
    }),
  }),
);

NewRegionSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  submitting: PropTypes.bool,
  edit: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function NewRegionSettingsForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <FlexBox flex={true} element={<form onSubmit={props.handleSubmit} />}>
      <FlexBox flex={true} direction="column">
        <Card>
          <FlexBox flex={true} element={<CardContent />}>
            <FlexBox flex={true} gutter={16} direction="column">
              <PageLoading isLoading={props.submitting} />

              <FlexBox flex={true}>
                <FormTextField
                  name="name"
                  fullWidth={true}
                  label={`${getLocalisationMessage("name", "Name")} *`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="code"
                  fullWidth={true}
                  label={`${getLocalisationMessage("code", "code")}`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="organizationCode"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "organization_code",
                    "Organization Code",
                  )}`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="organizationName"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "organization_name",
                    "Organization Name",
                  )}`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="impcName"
                  fullWidth={true}
                  label={`${getLocalisationMessage("impc_name", "IMPC Name")}`}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="impcCode"
                  fullWidth={true}
                  label={`${getLocalisationMessage("impc_code", "IMPC Code")}`}
                />
              </FlexBox>

              <FlexBox>
                <FormSelectField
                  name="type"
                  disabled={true}
                  fullWidth={true}
                  options={options}
                  formatOption={option => option}
                  label={`${getLocalisationMessage("type", "Type")}`}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox element={<CardActions />} justify="flex-end">
            <Button onClick={props.onDismiss}>
              {getLocalisationMessage("cancel", "Cancel")}
            </Button>

            <CustomButton
              startIcon={<Add />}
              variant={CONTAINED}
              type="submit"
              color={SECONDARY}
            >
              {props.edit
                ? getLocalisationMessage("update", "Update")
                : getLocalisationMessage("create", "Create")}
            </CustomButton>
          </FlexBox>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(NewRegionSettingsForm);
