import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";
import sprintf from "sprintf";

const MOBILE_NOTIFICATION_SETTINGS_CREATE_URL = `${API_ROOT_URL}/mobile/notification`;
const MOBILE_NOTIFICATION_SETTINGS_ITEM_URL = `${API_ROOT_URL}/mobile/notification/%s`;
const MOBILE_NOTIFICATION_SETTINGS_LIST_URL = `${API_ROOT_URL}/mobile/notification/listing`;

export const createMobileNotificationSettings = body =>
  api.post(MOBILE_NOTIFICATION_SETTINGS_CREATE_URL, {
    body,
  });

export const getMobileNotificationItem = id =>
  api.get(sprintf(MOBILE_NOTIFICATION_SETTINGS_ITEM_URL, id));

export const getMobileNotificationSettings = params =>
  api.get(MOBILE_NOTIFICATION_SETTINGS_LIST_URL, { params });
