import React from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "@material-ui/core";
import FlexBox from "./FlexBox";

const TitleTabs = props => (
  // const classes = useStyles();
  <Tabs {...props}>
    {props.items &&
      props.items.map((item, index) => (
        <Tab
          wrapped={true}
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            flex: index === 3 && "1 1 auto",
          }}
          tabIndex={item}
          id={`tab-${index}`}
          key={index}
          value={item}
          // label={item.title}
          label={<FlexBox>{item === "CN55" ? `${item} / CP94` : item}</FlexBox>}
        />
      ))}
  </Tabs>
);
TitleTabs.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf({
      title: PropTypes.string,
      value: PropTypes.any,
      icon: PropTypes.element,
    }),
  ]),
  onChange: PropTypes.func,
  isLink: PropTypes.bool,
  variant: PropTypes.oneOf(["fullWidth", "scrollable", "standard"]),
  value: PropTypes.any,
};

TitleTabs.defaultProps = {
  variant: "standard",
};

export default TitleTabs;
