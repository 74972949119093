import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import {
  isEqualData,
  isEqualDataIn,
  isEqualDataKeys,
} from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });

const InternationalSettingsAutocomplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(({ getById, input: { value } }) =>
      value && value.id && !value.name && getById
        ? getById(value.id)
            .map(item => ({ id: value.id, name: item.get("name") }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .distinctUntilChanged(isEqualDataKeys(["input.value.name", "parentId"]))
    .switchMap(({ chooseAPI, levelId, parentId, type, input: { value } }) =>
      chooseAPI
        ? chooseAPI(
            baseFilter
              .setSearch(value && value.name)
              .setValue("level_id", levelId)
              .setValue("parent_id", parentId)
              .setValue("type", type),
          )
            .map(fp.flow(fp.get("payload"), fp.toArray, fromJS))
            .catch(() => Observable.of(List()))
        : Observable.of(List()),
    )
    .distinctUntilChanged(isEqualData)
    .map(v => v.toJS());

  return propsStream.combineLatest(
    valueStream,
    optionsStream,
    ({ readOnly, getById, chooseAPI, type, ...props }, value, options) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={fp.flow(
          fp.toPlainObject,
          item =>
            type === "CN38"
              ? [item.name]
              : [item.name, item.code ? `(${item.code})` : item.code],
          fp.compact,
          fp.join(" "),
        )}
        input={{ ...props.input, value }}
        readOnly={!getById || !chooseAPI || readOnly}
      />
    ),
  );
});

FormInternationalSettingsAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,
  levelId: PropTypes.string,
  parentId: PropTypes.string,
  type: PropTypes.string,
};

FormInternationalSettingsAutocomplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormInternationalSettingsAutocomplete(props) {
  return <Field {...props} component={InternationalSettingsAutocomplete} />;
}
