import React from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MobileNotificationSettingsForm from "../../components/order-create-wizard-new/international-reports/MobileNotificationSettingsForm";
import { createMobileNotificationSettings } from "../../api/admin/AdminMobileNotificationSettingsApi";
import { MOBILE_NOTIFICATION_SETTINGS_URL } from "../../constants/AdminPathConstants";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({ setLocation: PropTypes.func }),
);

AdminMobileNotificationCreateWrapper.propTypes = {
  showErrorMessage: PropTypes.func,
  setLocation: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminMobileNotificationCreateWrapper(props) {
  const { getLocalisationMessage } = props;
  return (
    <AdminAppLayout
      title={getLocalisationMessage("new_notification", "New notification")}
    >
      <MobileNotificationSettingsForm
        onSubmit={values => {
          createMobileNotificationSettings(toSnakeCase(values))
            .then(res => {
              if (res && res.status === "success") {
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_created",
                    "Successfully Created",
                  ),
                );

                props.setLocation(
                  `${MOBILE_NOTIFICATION_SETTINGS_URL}?view_tab=notification`,
                );
              }
            })
            .catch(error => props.showErrorMessage(error));
        }}
        onDismiss={() =>
          props.setLocation(
            `${MOBILE_NOTIFICATION_SETTINGS_URL}?view_tab=notification`,
          )
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminMobileNotificationCreateWrapper);
