import React, { useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import FormDateField from "../form/FormDateField";
import { FieldArray, formValues, reduxForm } from "redux-form";
import FormTextField from "../form/FormTextField";
import { formatDate, formatText } from "../../helpers/FormatUtils";
import FormAutocompleteNew from "../form/FormInternationalSettingsAutocomplete";
import {
  getCacheRegion,
  getInternationalRegionChoose,
} from "../../api/admin/AdminInternationalReportsApi";
import { getValue } from "../../helpers/DataUtils";
import FormDateTimeField from "../form/FormDateTimeField";
import {
  getAirportsChoose,
  getCacheAirports,
  getCacheFlights,
  getFlightsChoose,
} from "../../api/admin/AdminIpsReportsApi";
import FormCN38SettingsAutocomplete from "../form/FormCN38SettingsAutocomplete";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { AddCircleOutline, Remove, Save } from "@material-ui/icons";
import { REPORTS_CN_38_URL } from "../../constants/AdminPathConstants";
import FormUserAutoComplete from "../form/FormUserAutoComplete";
import FormSelectField from "../form/FormSelectField";
import CN38ItemsDialog from "./CN38ItemsDialog";
import FormInternationalDepartureAutocomplete from "../form/FormInternationalDepartureAutocomplete";
import FormInternationalDestinationItemAutocomplete from "../form/FormInternationalDestinationItemAutocomplete";
import FormInternationalDepartureItemAutocomplete from "../form/FormInternationalDepartureItemAutocomplete";
import FormInternationalDestinationAirportSettingsAutocomplete from "../form/FormInternationalDestinationAirportSettingsAutocomplete";
import FormInternationalTransferAirportSettingsAutocomplete from "../form/FormInternationalTransferAirportSettingsAutocomplete";

const categories = ["A", "B", "C", "D"];

export const localisationCategory = (option, getLocalisationMessage) => {
  switch (option) {
    case "A":
      return "Авиа или Приоритетная почта";

    case "B":
      return "S.A.L. (Неприоритетеная почта)";

    case "C":
      return "Наземная почта (Неприоритетеная почта)";

    case "D":
      return "Приоритетная почта направленная наземным путен";

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

const Items = ({ fields }) => (
  <FlexBox flex={true} direction="column">
    <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
      <table
        border="1"
        cellSpacing="0"
        cellPadding="10"
        style={{ width: "100%", textAlign: "center" }}
      >
        <thead>
          <tr>
            <th colSpan="3" />
            <th colSpan="4">
              <FlexBox direction="column" align="center" justify="center">
                Количество
              </FlexBox>
            </th>
            <th colSpan="4">
              <FlexBox direction="column" align="center" justify="center">
                Вес
              </FlexBox>
            </th>
            <th colSpan="2" />
          </tr>
          <tr>
            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                Nº депеши
              </FlexBox>
            </th>
            <th style={{ width: "220px" }}>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                Откуда
              </FlexBox>
            </th>
            <th style={{ width: "220px" }}>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                Куда
              </FlexBox>
            </th>
            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                П/корр
              </FlexBox>
            </th>
            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                Посылки
              </FlexBox>
            </th>
            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                Tapa
              </FlexBox>
            </th>
            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                EMS
              </FlexBox>
            </th>
            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                П/корр
              </FlexBox>
            </th>
            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                Посылки
              </FlexBox>
            </th>
            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                Tapa
              </FlexBox>
            </th>
            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                EMS
              </FlexBox>
            </th>

            <th>
              <FlexBox
                style={{ gap: 10 }}
                direction="column"
                align="center"
                justify="center"
              >
                <FlexBox>Примечание</FlexBox>
              </FlexBox>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {fields.map((field, i) => (
            <tr key={i}>
              <td>
                <FlexBox>
                  <FormTextField
                    type="number"
                    name={`${field}.depecheNumber`}
                    fullWidth={true}
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox flex={true}>
                  <FormInternationalDepartureItemAutocomplete
                    getById={getCacheRegion}
                    chooseAPI={getInternationalRegionChoose}
                    name={`${field}.departure`}
                    type="CN38"
                    fullWidth={true}
                    renderOption={option => (
                      <FlexBox direction="column">
                        {getValue(option, "value.name", " ")}
                      </FlexBox>
                    )}
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormInternationalDestinationItemAutocomplete
                    getById={getCacheRegion}
                    chooseAPI={getInternationalRegionChoose}
                    name={`${field}.destination`}
                    type="CN38"
                    fullWidth={true}
                    renderOption={option => (
                      <FlexBox direction="column">
                        {getValue(option, "value.name", " ")}
                      </FlexBox>
                    )}
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormTextField
                    name={`${field}.qtyLetters`}
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormTextField
                    name={`${field}.qtyCp`}
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormTextField
                    name={`${field}.qtyTara`}
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormTextField
                    name={`${field}.qtyEms`}
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormTextField
                    name={`${field}.weightLetters`}
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormTextField
                    name={`${field}.weightCp`}
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormTextField
                    name={`${field}.weightTara`}
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormTextField
                    name={`${field}.weightEms`}
                    fullWidth={true}
                    type="number"
                  />
                </FlexBox>
              </td>
              <td>
                <FlexBox>
                  <FormTextField
                    type="number"
                    name={`${field}.observation`}
                    fullWidth={true}
                  />
                </FlexBox>
              </td>

              <td style={{ minWidth: 100 }}>
                <IconButton onClick={() => fields.remove(i)}>
                  <Remove color="error" />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </FlexBox>
  </FlexBox>
);

Items.propTypes = {
  fields: PropTypes.object,
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "CN38Form",
    enableReinitialize: true,
  }),
  formValues({
    departure: "departure",
    destination: "destination",
    items: "items",
  }),
);

CN38Form.propTypes = {
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  isLoading: PropTypes.bool,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  departure: PropTypes.object,
  items: PropTypes.array,
  destination: PropTypes.object,
};

function CN38Form(props) {
  const [open, setOpen] = useState(false);

  return (
    <form onSubmit={props.handleSubmit} style={{ height: "100%" }}>
      <CN38ItemsDialog
        open={open}
        onClose={() => setOpen(false)}
        onClick={values => {
          props.change("items", [
            ...props.items,
            {
              departure: values.departure,
              depecheNumber: Number(values.depecheNumber),
              destination: values.destination,
              observation: Number(values.observation),
              qtyCp: Number(values.qtyCp),
              qtyEms: Number(values.qtyEms),
              qtyLetters: Number(values.qtyLetters),
              qtyTara: Number(values.qtyTara),
              weightCp: Number(values.weightCp),
              weightTara: Number(values.weightTara),
              weightEms: Number(values.weightEms),
              weightLetters: Number(values.weightLetters),
            },
          ]);
          setOpen(false);
        }}
      />

      <Card style={{ height: "100%", padding: 20, overflow: "auto" }}>
        <CardContent style={{ height: "100%", overflow: "auto" }}>
          <FlexBox flex={true} direction="column" style={{ gap: 20 }}>
            <FlexBox flex={true} direction="column" style={{ gap: 13 }}>
              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Дата
                </Typography>

                <FormDateField
                  name="date"
                  fullWidth={true}
                  formatValue="dd.MM.yyyy"
                  formatDate={date => formatDate(date, "dd.MM.yyyy")}
                />
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Nº накладной
                </Typography>

                <FormTextField name="serial" fullWidth={true} />
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Учреждение подачи накладной
                </Typography>

                <FlexBox style={{ gap: 13, width: "100%" }}>
                  <FormInternationalDepartureAutocomplete
                    getById={getCacheRegion}
                    chooseAPI={getInternationalRegionChoose}
                    name="departure"
                    type="CN38"
                    fullWidth={true}
                    renderOption={option => (
                      <FlexBox direction="column">
                        <span>{getValue(option, "value.name", " ")}</span>
                      </FlexBox>
                    )}
                  />

                  <FlexBox>
                    <TextField
                      value={getValue(props.departure, "code")}
                      variant="outlined"
                      size="small"
                      disabled={true}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Учреждение назначения накладной
                </Typography>

                <FlexBox style={{ gap: 13, width: "100%" }}>
                  <FormAutocompleteNew
                    getById={getCacheRegion}
                    chooseAPI={getInternationalRegionChoose}
                    name="destination"
                    type="CN38"
                    fullWidth={true}
                    renderOption={option => (
                      <FlexBox direction="column">
                        <span>{getValue(option, "value.name", " ")}</span>
                      </FlexBox>
                    )}
                  />

                  <FlexBox>
                    <TextField
                      value={getValue(props.destination, "code")}
                      variant="outlined"
                      size="small"
                      disabled={true}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Nº рейса
                </Typography>

                <FormCN38SettingsAutocomplete
                  getById={getCacheFlights}
                  chooseAPI={getFlightsChoose}
                  name="flight"
                  fullWidth={true}
                />
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Дата и время отправки
                </Typography>

                <FormDateTimeField
                  name="dispatchDateTime"
                  fullWidth={true}
                  formatValue="dd.MM.yyyy HH:mm"
                  formatDate={date => formatDate(date, "dd.MM.yyyy HH:mm")}
                />
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Аэропорт прямой перегрузки
                </Typography>

                <FormInternationalTransferAirportSettingsAutocomplete
                  getById={getCacheAirports}
                  chooseAPI={getAirportsChoose}
                  name="transferAirport"
                  fullWidth={true}
                />
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Аэропорт выгрузки
                </Typography>

                <FormInternationalDestinationAirportSettingsAutocomplete
                  getById={getCacheAirports}
                  chooseAPI={getAirportsChoose}
                  name="destinationAirport"
                  fullWidth={true}
                  renderOption={option => (
                    <FlexBox direction="column">
                      <span>{getValue(option, "value.name", " ")}</span>
                    </FlexBox>
                  )}
                />
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Путь следования
                </Typography>

                <FormTextField name="" fullWidth={true} />
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Кол-во накладных
                </Typography>

                <FormTextField
                  name="numberOfWaybill"
                  type="number"
                  fullWidth={true}
                />
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Категория почты{" "}
                </Typography>

                <FormSelectField
                  name="category"
                  fullWidth={true}
                  options={categories}
                  formatOption={option =>
                    localisationCategory(option, props.getLocalisationMessage)
                  }
                />
              </FlexBox>

              <FlexBox style={{ gap: 13 }} justify="center" align="center">
                <Typography style={{ width: 400, textAlign: "right" }}>
                  Штрих - код
                </Typography>

                <FormTextField name="barcode" fullWidth={true} />
              </FlexBox>

              <FlexBox style={{ marginTop: ".5rem" }} align={ALIGN_CENTER}>
                <CustomButton
                  color={SECONDARY}
                  variant={OUTLINED}
                  onClick={() => {
                    setOpen(true);
                  }}
                  startIcon={<AddCircleOutline />}
                >
                  {props.getLocalisationMessage("add_more", "Add More")}
                </CustomButton>
              </FlexBox>
            </FlexBox>

            <FlexBox>
              <FieldArray
                name="items"
                fullWidth={true}
                getLocalisationMessage={props.getLocalisationMessage}
                component={Items}
              />
            </FlexBox>

            <FlexBox
              style={{ gap: 13, marginBottom: 30 }}
              flex={true}
              justify="center"
              align="flex-end"
            >
              <FormUserAutoComplete
                name="director"
                fullWidth={true}
                label={`${props.getLocalisationMessage("director")} *`}
              />
              <FormUserAutoComplete
                name="operator"
                fullWidth={true}
                label={`${props.getLocalisationMessage("operator")} *`}
              />
            </FlexBox>
          </FlexBox>
        </CardContent>

        <FlexBox
          flex={true}
          justify="flex-end"
          align="flex-end"
          style={{
            gap: 16,
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "100%",
            background: "#fff",
          }}
        >
          <CardActions>
            <FlexBox justify="flex-end">
              <CustomButton
                style={{ width: 200 }}
                fullWidth={true}
                variant={OUTLINED}
                color={SECONDARY}
                size="large"
                onClick={() => props.setLocation(REPORTS_CN_38_URL)}
              >
                {props.getLocalisationMessage("dismiss", "Dismiss")}
              </CustomButton>
            </FlexBox>

            <FlexBox>
              <CustomButton
                style={{ width: 200 }}
                fullWidth={true}
                variant={CONTAINED}
                color={SECONDARY}
                disabled={props.items && props.items.length === 0}
                type="submit"
                size="large"
                startIcon={
                  props.isLoading ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <Save />
                  )
                }
              >
                {props.getLocalisationMessage("save", "Save")}
              </CustomButton>
            </FlexBox>
          </CardActions>
        </FlexBox>
      </Card>
    </form>
  );
}

export default enhancer(CN38Form);
