import React, { useState } from "react";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { pipeStreams } from "../../helpers/StreamUtils";
import fp from "lodash/fp";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import { Observable } from "rxjs";
import { fromJS, List, Map } from "immutable";
import DataListFilter from "../../helpers/DataListFilter";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { Card, CardContent } from "@material-ui/core";
import CustomsList from "../../components/order-customs-sorting/CustomsList";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import { makeStyles } from "@material-ui/core/styles";
import { toCustomsFilter } from "../../helpers/CustomsFilterMapper";
import OrderCustomsSearchWrapper from "../../components/order-customs-sorting/OrderCustomsSearchWrapper";
import { getBatchOrderUpdateList } from "../../api/admin/AdminBatchApi";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import OrderCustomsFilterForm from "../../components/order-customs-sorting/OrderCustomsFilterForm";
import { endOfDay, startOfDay, subDays } from "date-fns";
import Redirect from "../../components/router/Redirect";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import OrderCustomsBatchUpdate from "../../components/order-customs-sorting/OrderCustomsBatchUpdate";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import { batchAsyncUpdateOrder } from "../../api/admin/AdminOrderApi";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { HOLD_ON_AT_CUSTOMS } from "../../constants/OrderStatusCodes";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";

const useStyles = makeStyles(() => ({
  topContent: {
    display: "flex",
    flex: "1 1 100%",
  },
  marginRight: {
    marginRight: 15,
  },
  tabs: {
    "& .MuiTab-root": {
      backgroundColor: "#fff",
    },
  },
  filter: {
    flex: 1,
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
}));

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      userWarehouseId: getUserWarehouseId(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toCustomsFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getBatchOrderUpdateList(
              props.filter.setValue("actions", "CUSTOMS_PROCESS"),
            )
              .repeatWhen(() => onRequestRefreshStream)
              .catch(() => Observable.of({})),
          )
          .startWith({})
          .map(response => fromJS(response))
          .map(response =>
            Map({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
          );

        return propsStream
          .combineLatest(listResponseStream, (props, listResponse) => ({
            ...props,
            onRequestRefresh,
            list: listResponse.get("list"),
            total: listResponse.get("total"),
            isLoading: listResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminCustomsListContainer.propTypes = {
  isLoading: PropTypes.bool,

  total: PropTypes.number,
  userWarehouseId: PropTypes.number,

  location: PropTypes.object,

  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),

  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminCustomsListContainer(props) {
  const { getLocalisationMessage, location } = props;
  const classes = useStyles();
  const [batchId, setBatchId] = useState(null);

  const [completeTask, setCompleteTask] = useState(false);

  return (
    <AdminAppLayout
      slug="customs_list"
      title={getLocalisationMessage("order_customs", "Customs clearance")}
    >
      <Redirect
        when={
          !(
            "from_date_time" in location.query &&
            "to_date_time" in location.query
          )
        }
        to={updateQuery(
          location,
          fp.flow(
            fp.set(
              "from_date_time",
              formatDateTimeToUrl(subDays(startOfDay(new Date()), 30)),
            ),
            fp.set("to_date_time", formatDateTimeToUrl(endOfDay(new Date()))),
          ),
        )}
      />

      {fp.toFinite(batchId) > 0 && (
        <AdminBatchUpdatesItemDialogWrapper
          batchId={fp.toFinite(batchId)}
          isCustoms={true}
          onRequestClose={() => {
            setBatchId(null);
          }}
        />
      )}

      <OrderCustomsBatchUpdate
        open={completeTask}
        initialValues={{
          warehouse: {
            id: props.userWarehouseId,
          },
          privacy: "public",
          supplier: {
            id: 1,
          },
        }}
        onRequestClose={() => setCompleteTask(false)}
        onSubmit={values =>
          batchAsyncUpdateOrder({
            ...toSnakeCase(values),
            customs_batch_update: values.orderStatus === HOLD_ON_AT_CUSTOMS,
          })
            .then(res => {
              props.showSuccessMessage(
                getLocalisationMessage("successfully_updated"),
              );

              setBatchId(getValue(res, "data.id"));
              setCompleteTask(null);
              props.onRequestRefresh();
            })
            .catch(err => props.showErrorMessage(err))
        }
      />

      <FlexBox direction="column" flex={true}>
        <FlexBox style={{ marginBottom: 10 }} element={<Card />}>
          <CardContent className={classes.topContent}>
            <FlexBox flex={true} align={ALIGN_CENTER}>
              <FlexBox flex={true}>
                <OrderCustomsSearchWrapper
                  onChange={v => {
                    props.setLocationQuery(fp.set("search", v.join(",")));
                  }}
                />
              </FlexBox>

              <FlexBox flex={true} justify="flex-end" style={{ gap: 16 }}>
                <OrderCustomsFilterForm
                  initialValues={{
                    fromDateTime: safeParseDate(
                      props.filter.getValue("from_date_time"),
                    ),
                    toDateTime: safeParseDate(
                      props.filter.getValue("to_date_time"),
                    ),
                  }}
                  onSubmit={values => {
                    props.setLocationQueryFilter(
                      props.filter.withMutations((filter: DataListFilter) => {
                        filter.setValueMap({
                          from_date_time: formatDateTimeToUrl(
                            values.fromDateTime,
                          ),
                          to_date_time: formatDateTimeToUrl(values.toDateTime),
                        });
                      }),
                    );
                  }}
                />

                <FlexBox>
                  <CustomButton
                    variant={CONTAINED}
                    color={SECONDARY}
                    style={{ backgroundColor: "#3F51B5" }}
                    size="large"
                    fullWidth={true}
                    onClick={() => setCompleteTask(true)}
                  >
                    {getLocalisationMessage(
                      "update_customs_status",
                      "Update customs status",
                    )}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </CardContent>
        </FlexBox>

        <FlexBox element={<Card />} flex={true}>
          <CustomsList
            isLoading={props.isLoading}
            totalCount={props.total}
            list={props.list}
            filter={props.filter}
            maxSearchItems={0}
            onFilterChange={f => props.setLocationQueryFilter(f)}
            overscanRowCount={6}
            rowCount={props.list.size}
            rowGetter={options => props.list.get(options.index)}
            location={props.location}
          />
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCustomsListContainer);
