import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomButton, {
  CONTAINED,
  DEFAULT,
  OUTLINED,
  PRIMARY,
  SECONDARY,
  TEXT,
} from "./CustomButton";
import FormDialog from "../form/FormDialog";
import FlexBox from "./FlexBox";

ConfirmationButton.propTypes = {
  children: PropTypes.node,

  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,

  variant: PropTypes.oneOf([OUTLINED, CONTAINED, TEXT]),
  color: PropTypes.oneOf([PRIMARY, SECONDARY, DEFAULT]),
  buttonLabel: PropTypes.oneOf([PropTypes.node, PropTypes.string]).isRequired,

  onConfirm: PropTypes.func.isRequired,
};

ConfirmationButton.defaultProps = {
  variant: CONTAINED,
  color: SECONDARY,
  size: "large",
  fullWidth: false,
  disabled: false,
};

function ConfirmationButton(props) {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <FlexBox flex={props.fullWidth}>
      <FormDialog
        open={openDialog}
        onRequestClose={() => setOpenDialog(false)}
        onSubmit={() => {
          setOpenDialog(false);
          props.onConfirm();
        }}
      >
        {props.children}
      </FormDialog>

      <CustomButton
        variant={props.variant}
        color={props.color}
        size={props.size}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        {props.buttonLabel}
      </CustomButton>
    </FlexBox>
  );
}

export default ConfirmationButton;
