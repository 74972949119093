import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PageLoading from "../../ui-core/PageLoading";
import FlexBox from "../../ui-core/FlexBox";
import FormTextField, { OUTLINED, SECONDARY } from "../../form/FormTextField";
import CustomButton, { CONTAINED } from "../../ui-core/CustomButton";
import { formatText } from "../../../helpers/FormatUtils";
import fp from "lodash/fp";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../../reducers/NotificationsReducer";
import { isValidObjectId } from "../../../helpers/ValidateUtils";
import FormAutoComplete from "../../form/FormAutoComplete";
import FormServiceTypeByIdAutocomplete from "../../form/FormServiceTypeByIdAutocomplete";
import FormCheckbox from "../../form/FormCheckbox";
import FormJMAutoComplete from "../../form/FormJMAutoComplete";
import { CircularProgress } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import FormSelectField from "../../form/FormSelectField";

const notificationTypes = ["BOT"];

const minutes = ["00", "30"];

const getValues = getFormValues("OrderFlowOperationForm");

export const localisationNotificationType = (
  option,
  getLocalisationMessage,
) => {
  switch (option) {
    case "BOT":
      return getLocalisationMessage("telegram_bot", "Telegram Bot");

    case "PUSH":
      return getLocalisationMessage("push", "Push");

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage },
  ),
  reduxForm({
    form: "OrderFlowOperationForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      serviceType: !isValidObjectId(values.serviceType)
        ? props.getLocalisationMessage("this_field_is_required")
        : null,
      promise:
        !values.promise &&
        props.getLocalisationMessage("this_field_is_required"),
      notificationType:
        !values.notificationType &&
        props.getLocalisationMessage("this_field_is_required"),
      notificationTime:
        !values.notificationTime &&
        props.getLocalisationMessage("this_field_is_required"),
      repetition:
        !values.repetition &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
);

const OrderFlowOperationForm = ({
  getLocalisationMessage,
  handleSubmit,
  submitting,
  values,
  change,
  onClose,
  update,
  success,
  setSuccess,
  reset,
  isLoadingSubmit,
}) => {
  const [clearAdd, setClearAdd] = useState(false);
  const [ignore, setIgnore] = useState(true);

  useEffect(() => {
    if (success) {
      if (clearAdd) {
        setTimeout(() => {
          if (update) {
            reset();
            fp.keys(values).forEach(key => {
              change(key, null);
              setClearAdd(false);
              setSuccess(false);
              setIgnore(false);
            });
          } else {
            [
              "serviceType",
              "fromJurisdiction",
              "notificationType",
              "minute",
            ].forEach(objectKey => {
              const fieldValue = values[objectKey];
              change(objectKey, fieldValue !== undefined ? fieldValue : null);
            });
            setClearAdd(false);
            setSuccess(false);
            setIgnore(false);
          }
        }, 200);
      }
    }
  }, [success]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <PageLoading isLoading={submitting} />
      <FlexBox container={8} direction="column" style={{ gap: 13 }}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <FormServiceTypeByIdAutocomplete
              autoSelectOneOption={true}
              fullWidth={true}
              name="serviceType"
              label={
                isValidObjectId(values.customer)
                  ? getLocalisationMessage("service_type", "Service Type")
                  : `${getLocalisationMessage(
                      "service_type",
                      "Service Type",
                    )} *`
              }
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormJMAutoComplete
              autoSelectOneOption={!update}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>{option.name}</span>
                  <span
                    style={{
                      fontSize: ".8rem",
                      fontStyle: "italic",
                    }}
                  >
                    {option.value.hierarchy.map((item, index, arr) =>
                      index === arr.length - 1 ? item.name : `${item.name} > `,
                    )}
                  </span>
                </FlexBox>
              )}
              fullWidth={true}
              name="fromJurisdiction"
              label={getLocalisationMessage(
                "from_jurisdiction",
                "From Jurisdiction",
              )}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormJMAutoComplete
              autoSelectOneOption={false}
              renderOption={option => (
                <FlexBox direction="column">
                  <span>{option.name}</span>
                  <span
                    style={{
                      fontSize: ".8rem",
                      fontStyle: "italic",
                    }}
                  >
                    {option.value.hierarchy.map((item, index, arr) =>
                      index === arr.length - 1 ? item.name : `${item.name} > `,
                    )}
                  </span>
                </FlexBox>
              )}
              fullWidth={true}
              name="toJurisdiction"
              label={getLocalisationMessage(
                "to_jurisdiction",
                "To Jurisdiction",
              )}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormTextField
              name="promise"
              type="number"
              fullWidth={true}
              label={`${getLocalisationMessage(
                "promise_time_hour",
                "Promise Time (hour)",
              )} *`}
              ignoreError={ignore}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormSelectField
              name="minute"
              fullWidth={true}
              options={minutes}
              formatOption={option => `${option} min`}
              label={`${getLocalisationMessage(
                "promise_time_min",
                "Promise Time (min)",
              )} *`}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormAutoComplete
              name="notificationType"
              fullWidth={true}
              options={notificationTypes}
              formatOption={option =>
                localisationNotificationType(option, getLocalisationMessage)
              }
              label={`${getLocalisationMessage(
                "notification_type",
                "Notification Type",
              )}*`}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              name="notificationTime"
              fullWidth={true}
              type="number"
              label={`${getLocalisationMessage(
                "reminder_time_min",
                "Reminder Time (minutes) ",
              )} *`}
              ignoreError={ignore}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              type="number"
              name="repetition"
              fullWidth={true}
              label={`${getLocalisationMessage(
                "reminder_count",
                "Reminder count",
              )} *`}
              ignoreError={ignore}
            />
          </FlexBox>

          {update && (
            <FlexBox flex={true} align="center" justify="center">
              <FormCheckbox
                name="active"
                label={getLocalisationMessage("active", "Active")}
              />
            </FlexBox>
          )}
        </FlexBox>

        <FlexBox justify="flex-end">
          <FlexBox style={{ gap: 16 }} justify="flex-end">
            <CustomButton
              onClick={() => {
                setSuccess(true);
                setClearAdd(true);
                onClose();
                reset();
              }}
              style={{
                height: 40,
                minWidth: 220,
                borderColor: "#3F51B5",
                color: "#3F51B5",
              }}
              fullWidth={true}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("discard", "Discard")}
            </CustomButton>
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              type="submit"
              style={{
                height: 40,
                minWidth: 220,
              }}
              fullWidth={true}
              onClick={() => {
                setIgnore(true);
                setClearAdd(true);
              }}
              disabled={isLoadingSubmit}
              endIcon={
                isLoadingSubmit ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <Check />
                )
              }
            >
              {update
                ? getLocalisationMessage("update", "update")
                : getLocalisationMessage("add", "Add")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
};
OrderFlowOperationForm.propTypes = {
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  update: PropTypes.bool,
  isLoadingSubmit: PropTypes.bool,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
  onClose: PropTypes.func,
  setSuccess: PropTypes.func,
  reset: PropTypes.func,
};
export default enhancer(OrderFlowOperationForm);
