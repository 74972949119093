import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "CustomsCheckForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      barcodes:
        fp.size(values.barcodes) === 0 &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  formValues({
    orderStatus: "orderStatus",
  }),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
);

CustomsCheckForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
};

CustomsCheckForm.defaultProps = {
  disableBarcodes: false,
};

function CustomsCheckForm(props) {
  const { getLocalisationMessage, classes } = props;

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 1000,
          maxWidth: 1000,
        },
      }}
    >
      <form onSubmit={props.handleSubmit}>
        <PageLoading isLoading={props.submitting} />
        <DialogTitle
          style={{ color: props.theme.palette.appBarTextColor }}
          className={classes.dialogTitle}
        >
          {getLocalisationMessage(
            "for_customs_checkout",
            "For Customs Checkout",
          )}
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <FlexBox container={8} style={{ gap: 16 }}>
            <FlexBox flex={true}>
              <FormChipInput
                name="barcodes"
                focus={true}
                margin="normal"
                fullWidth={true}
                label={getLocalisationMessage("orders_barcodes")}
              />
            </FlexBox>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onRequestClose} primary={true}>
            {getLocalisationMessage("dismiss")}
          </Button>

          <Button type="submit" primary={true}>
            {getLocalisationMessage("submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default enhancer(CustomsCheckForm);
