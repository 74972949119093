import React from "react";
import PropTypes from "prop-types";
import ControlledEditor from "./ControlledEditor";

EditorFieldComponent.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,

  label: PropTypes.node,
};

function EditorFieldComponent(props) {
  const {
    meta,
    input: { onChange, value },
  } = props;

  return (
    <ControlledEditor
      onChange={onChange}
      value={value}
      label={props.label}
      errorText={meta.touched && meta.error}
    />
  );
}

export default EditorFieldComponent;
