import { Observable } from "rxjs";
import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { getUser, hasUserPermission } from "../../reducers/ProfileReducer";
import { isEReceiptOptionEnabled } from "../../reducers/MarketplaceReducer";
import {
  getCurrentLanguage,
  getMessages,
} from "../../reducers/LocalizationReducer";
import {
  ADDITIONAL_SERVICES_SETTINGS_URL,
  BANK_SETTINGS_LIST_URL,
  BATCH_CATEGORIES_LIST_URL,
  CALL_STEP_SETTINGS_LIST_URL,
  COMMUNICATION_SETTINGS_LIST_URL,
  HOLIDAY_SETTINGS_URL,
  MOBILE_NOTIFICATION_SETTINGS_URL,
  OPIS_CATEGORIES_LIST_URL,
  ORDER_FLOW_URL,
  ORDER_RULE_LIST_URL,
  ORDER_RULES_GROUP_LIST_URL,
  PACKAGE_RULE_LIST_URL,
  PDF_SETTINGS_URL,
  SAMPLE_ITEMS_LIST_URL,
  SAMPLE_ITEMS_SETTINGS_URL,
  SCORE_SETTINGS_URL,
  SETTINGS_MAP_URL,
  SETTINGS_MARKETPLACE_DETAILS_URL,
  SETTINGS_MARKETPLACES_URL,
  SETTINGS_ORDER_URL,
  SETTINGS_PRICING_PLANS_URL,
  SETTINGS_SMS_PROVIDER_URL,
  SLA_RULE_LIST_URL,
  SUPPLIER_FINANCE_SETTINGS_URL,
  TERMS_SETTINGS_URL,
} from "../../constants/AdminPathConstants";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import SettingsItem from "../../components/settings-core/SettingsItem";
import { WING_AE_ID } from "../../../server/constants/MarketplaceId";
import {
  ROLE_MARKETPLACE_ADMIN,
  ROLE_MARKETPLACE_OWNER,
  ROLE_SUPER_ADMIN,
} from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";
import { makeStyles } from "@material-ui/core";
import { formatText } from "../../helpers/FormatUtils";

const useStyles = makeStyles({
  flexRoot: {
    flexWrap: "wrap",
  },
  flexChild: {
    width: "calc(33.3%)",
  },
});
const enhancer = compose(
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      i18n: getMessages(state),
      isEReceiptOptionEnabled: isEReceiptOptionEnabled(state),
      isSuperAdmin: hasRole(userRoles, ROLE_SUPER_ADMIN),
      isMarketplaceAdmin: hasRole(userRoles, ROLE_MARKETPLACE_ADMIN),
      isMarketplaceOwner: hasRole(userRoles, ROLE_MARKETPLACE_OWNER),
      productCategory: hasUserPermission(state, "ADMIN_PRODUCT_CATEGORY"),
    };
  }),
  withState("language", "setLanguage", getCurrentLanguage()),
  getContext({ setLocation: PropTypes.func.isRequired }),
  mapPropsStream(propsStream => {
    const marketplaceStream = getMarketplace()
      .takeLast(1)
      .catch(() => Observable.of({}))
      .map(
        fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS, x =>
          x.get("marketplace_id"),
        ),
      );

    return propsStream
      .combineLatest(marketplaceStream, (props, marketplaceId) => ({
        ...props,
        marketplaceId,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSettingsDashboardContainer.propTypes = {
  setLocation: PropTypes.func,
  marketplaceId: PropTypes.number,
  isMarketplaceOwner: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isMarketplaceAdmin: PropTypes.bool,
  productCategory: PropTypes.bool,
  i18n: PropTypes.instanceOf(Immutable.Map),
};

function AdminSettingsDashboardContainer({
  i18n,
  isMarketplaceAdmin,
  isMarketplaceOwner,
  isSuperAdmin,
  marketplaceId,
  setLocation,
  productCategory,
}) {
  const { flexChild, flexRoot } = useStyles();

  const hidden = false;

  return (
    <AdminAppLayout
      slug="settings_dashboard"
      title={i18n.get("settings_dashboard", "Settings Dashboard")}
    >
      <FlexBox gutter={8} className={flexRoot}>
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("notification_settings", "Notification Settings")}
            onClick={() => setLocation(COMMUNICATION_SETTINGS_LIST_URL)}
          />
        </FlexBox>
        {hidden && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("orders", "Orders")}
              onClick={() => setLocation(SETTINGS_ORDER_URL)}
            />
          </FlexBox>
        )}
        {hidden && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("score_settings", "Score Settings")}
              onClick={() => setLocation(SCORE_SETTINGS_URL)}
            />
          </FlexBox>
        )}
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("allocation_rules", "Allocation Rules")}
            onClick={() => setLocation(ORDER_RULE_LIST_URL)}
          />
        </FlexBox>
        {marketplaceId === WING_AE_ID && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get(
                "allocation_rules_group",
                "Allocation Rules Group",
              )}
              onClick={() => setLocation(ORDER_RULES_GROUP_LIST_URL)}
            />
          </FlexBox>
        )}
        {hidden && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("language_settings", "Language Settings")}
            />
          </FlexBox>
        )}
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("holiday_settings", "Holiday Settings")}
            onClick={() => setLocation(HOLIDAY_SETTINGS_URL)}
          />
        </FlexBox>
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("package_rule_settings", "Package Rule Settings")}
            onClick={() => setLocation(PACKAGE_RULE_LIST_URL)}
          />
        </FlexBox>
        {marketplaceId === WING_AE_ID && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get(
                "supplier_finance_settings",
                "Supplier Finance Settings",
              )}
              onClick={() => setLocation(SUPPLIER_FINANCE_SETTINGS_URL)}
            />
          </FlexBox>
        )}
        {hidden && (
          <FlexBox className={flexChild}>
            <SettingsItem title={i18n.get("time_settings", "Time Settings")} />
          </FlexBox>
        )}
        {marketplaceId === WING_AE_ID && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("bank_settings", "Bank Settings")}
              onClick={() => setLocation(BANK_SETTINGS_LIST_URL)}
            />
          </FlexBox>
        )}
        {marketplaceId === WING_AE_ID && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("call_step_settings", "Call Step Settings")}
              onClick={() => setLocation(CALL_STEP_SETTINGS_LIST_URL)}
            />
          </FlexBox>
        )}
        {marketplaceId === WING_AE_ID && isMarketplaceOwner && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("marketplaces_settings", "Marketplaces Settings")}
              onClick={() => setLocation(SETTINGS_MARKETPLACES_URL)}
            />
          </FlexBox>
        )}
        {marketplaceId === WING_AE_ID && isMarketplaceOwner && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("pricing_plans", "Pricing Plans")}
              onClick={() => setLocation(SETTINGS_PRICING_PLANS_URL)}
            />
          </FlexBox>
        )}
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("communication_settings", "Communication Settings")}
            onClick={() => setLocation(SETTINGS_SMS_PROVIDER_URL)}
          />
        </FlexBox>
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("sla_management_tool", "SLA Management Tool")}
            onClick={() => setLocation(SLA_RULE_LIST_URL)}
          />
        </FlexBox>
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("sample_items", "Sample Items")}
            onClick={() => setLocation(SAMPLE_ITEMS_LIST_URL)}
          />
        </FlexBox>
        {isSuperAdmin && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("sample_items_settings", "Sample Items Settings")}
              onClick={() => setLocation(SAMPLE_ITEMS_SETTINGS_URL)}
            />
          </FlexBox>
        )}
        {isMarketplaceAdmin && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("company_setting", "Company Settings")}
              onClick={() => setLocation(SETTINGS_MARKETPLACE_DETAILS_URL)}
            />
          </FlexBox>
        )}
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("map_settings", "Map Settings")}
            onClick={() => setLocation(SETTINGS_MAP_URL)}
          />
        </FlexBox>
        {isSuperAdmin && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("pdf_settings", "PDF Settings")}
              onClick={() => setLocation(PDF_SETTINGS_URL)}
            />
          </FlexBox>
        )}
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("terms_settings", "Terms Settings")}
            onClick={() => setLocation(TERMS_SETTINGS_URL)}
          />
        </FlexBox>
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("additional_services")}
            onClick={() => setLocation(ADDITIONAL_SERVICES_SETTINGS_URL)}
          />
        </FlexBox>
        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("batch_categories")}
            onClick={() => setLocation(BATCH_CATEGORIES_LIST_URL)}
          />
        </FlexBox>

        {productCategory && (
          <FlexBox className={flexChild}>
            <SettingsItem
              title={i18n.get("product_categories", "Product Categories")}
              onClick={() => setLocation(OPIS_CATEGORIES_LIST_URL)}
            />
          </FlexBox>
        )}

        <FlexBox className={flexChild}>
          <SettingsItem
            title={formatText(
              i18n.get("delivery_time_settings", "Delivery time settings"),
            )}
            onClick={() => setLocation(ORDER_FLOW_URL)}
          />
        </FlexBox>

        <FlexBox className={flexChild}>
          <SettingsItem
            title={i18n.get("mobile_app_settings", "Mobile app settings")}
            onClick={() => setLocation(MOBILE_NOTIFICATION_SETTINGS_URL)}
          />
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminSettingsDashboardContainer);
