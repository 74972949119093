import React, { useEffect, useRef, useState } from "react";
import { fromJS, List, Map } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { FilterList, Print, Refresh, Search } from "@material-ui/icons";
import { getValue } from "../../helpers/DataUtils";
import { formatDateValuesForDownloadCSV } from "../../helpers/OrderListHelper";
import {
  getAVVALUserId,
  getUser,
  getUserWarehouseId,
  hasUserPermission,
} from "../../reducers/ProfileReducer";
import {
  getCurrentLanguage,
  getMessages,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import {
  batchBarcodesStatusUpdate,
  getArchivePeriod,
  getDynamicFields,
  getOrderList2,
  updateOrderIssuance,
  updateRecipientPostcode,
  uploadOrderSignature,
} from "../../api/admin/AdminOrderApi";
import AdminBatchUpdateOrderDialogWrapper from "../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import DownloadDynamicCSVDialog from "../../components/csv-core/DownloadDynamicCSVDialog";
import OrderListErrorNotification from "../../components/orders-core/OrderListErrorNotification";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { BARCODES_PDF } from "../../api/admin/AdminBatchApi";
import {
  COD_RECEIPT_URL,
  IS_DOWNLOADING,
  IS_PRINTING,
  RefundInfo,
  serviceId,
  urlAVVAL,
  ZEBRA_URL,
} from "../../components/orders-core/AdminPostForm";
import { getPDF } from "../../helpers/FormUtils";
import { COLUMN, RENDER } from "../../components/orders-core/MUITable";
import OrdersMUITable from "../../components/orders-core/OrdersMUITable";
import _ from "lodash";
import adminColumnOptions from "../../constants/marketplace/AdminArchiveOrderListConstants";
import fp from "lodash/fp";
import BatchRedirectOrdersFormDialogWrapper from "../../wrappers/admin/BatchRedirectOrdersFormDialogWrapper";
import BatchRTOOrdersFormDialogWrapper from "../../wrappers/admin/BatchRTOOrdersFormDialogWrapper";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { COD } from "../../constants/OrderChargeItemTypes";
import {
  getCheckId,
  getOrder2,
  setReceipt,
} from "../../api/v2/admin/AdminOrderApi";
import OrderDetailsDialogIssuance2 from "../../components/order-details-dialog/OrderDetailsDialogIssuance2";
import BatchAssignOrdersToDriverFormDialogWrapper from "../../wrappers/admin/BatchAssignOrdersToDriverFormDialogWrapper";
import OrderSortingRedirectOrderDialog from "../../components/order-outbound-sorting/OrderSortingRedirectOrderDialog";
import ResponseError from "../../helpers/ResponseError";
import { OrderSortingDB } from "../../firebase/OrderSortingDB";
import AdminOrderDetailsDialogWrapperForOrderList from "../../wrappers/admin/AdminOrderDetailsDialogWrapperForOrderList";
import { CARD, CASH } from "../../constants/OrderPaymentTypes";
import {
  formatDateTimeToUrlWithSeconds,
  formatErrorMessage,
} from "../../helpers/FormatUtils";
import {
  isOtpSettingsServiceType,
  isOtpSettingsTransportationType,
} from "../../reducers/MarketplaceReducer";
import { avvalSearchUrl } from "../../components/orders-core/CommissionTINDialog";
import AppTitle from "../../components/deprecated/AppTitle";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import AdminArchiveOrderFilterWrapper from "../../wrappers/admin/AdminArchiveOrderFilterWrapper";
import { CANCELLED, isNotFinalStatus } from "../../constants/OrderStatusCodes";
import { RUSSIAN_LANGUAGE, UZBEK_LANGUAGE } from "../../constants/LocaleTypes";
import { subDays } from "date-fns";

const cleanupValues = fp.omit(["from_date_time", "to_date_time"]);

const options = commissionTin => ({
  method: "POST",
  headers: {
    Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    search_inn_comm: {
      comm_inn: commissionTin,
    },
  }),
});

const uzbekMonths = [
  "Yanvar",
  "Fevral",
  "Mart",
  "Aprel",
  "May",
  "Iyun",
  "Iyul",
  "Avgust",
  "Sentyabr",
  "Oktyabr",
  "Noyabr",
  "Dekabr",
];

const formatMonthLocalise = (date, currentLanguage) => {
  switch (currentLanguage) {
    case UZBEK_LANGUAGE:
      return uzbekMonths[date.getMonth()];
    case RUSSIAN_LANGUAGE:
      return date.toLocaleString("ru-RU", { month: "long" });

    default:
      return date.toLocaleString("en-US", { month: "long" });
  }
};

const useStyles = makeStyles({
  pageFab: { bottom: "64px" },
  appBarRightAction: { height: "100%" },
  appBarRightActionToggle: {
    whiteSpace: "nowrap",
    marginLeft: "12px",
  },
  mr10: {
    marginRight: 10,
  },
  pLR1rem: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  filterToggle: { marginTop: "12px" },
  btn: {
    flex: "1 1 auto",
    margin: "0 .5rem",
  },
  btnWrapper: {
    margin: "0 -.5rem",
  },
  titleWithBorder2: {
    textAlign: "center",
    borderBottom: "1px solid #eeeeee",
  },
  content: {
    minWidth: "1200px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  tabs: {
    flexGrow: 1,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  createSimpleButton: {
    "& .MuiButton-contained:hover": {
      backgroundColor: "#1976d2",
      color: "#fff",
    },
  },
  select: {
    "& .MuiSelect-select.MuiSelect-select": {
      fontSize: 16,
      background: "transparent",
    },
  },
});
const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userWarehouse = getUser(state).get("warehouse");
      return {
        userIdAVVAL: getAVVALUserId(state),
        hasBatchUpdatePermission: hasUserPermission(
          state,
          "ADMIN_ORDER_BATCH_MODAL_VIEW",
        ),
        createSimpleOrder: hasUserPermission(state, "ADMIN_SIMPLE_ORDER"),
        userWarehouse,
        i18n: getMessages(state),
        userWarehouseId: getUserWarehouseId(state),
        currentLanguage: getCurrentLanguage(state),
        isOtpSettingsServiceType: isOtpSettingsServiceType(state),
        isOtpSettingsTransportationType: isOtpSettingsTransportationType(state),
      };
    },
    { showSuccessMessage, showErrorMessage },
  ),
);

const AdminArchiveOrderList = ({
  i18n,
  userWarehouseId,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  userWarehouse,
  hasBatchUpdatePermission,
  userIdAVVAL,
  // eslint-disable-next-line no-shadow
  isOtpSettingsServiceType,
  // eslint-disable-next-line no-shadow
  isOtpSettingsTransportationType,
  location,
  setLocationQuery,
  currentLanguage,
}) => {
  const classes = useStyles();
  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);
  const [isOpenDynamicDownloadCSV, setIsOpenDynamicDownloadCSV] = useState(
    false,
  );
  const [isOpenBatchRedirectOrders, setIsOpenBatchRedirectOrders] = useState({
    open: false,
    is_redirect: false,
  });

  const orderBarcode = getValue(location, "query.orderBarcode");
  const [isOpenRedirect, setIsOpenRedirect] = useState(false);
  const [isOpenDispatch, setIsOpenDispatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRTO, setIsOpenRTO] = useState(false);
  const [isOpenIssuance, setIsOpenIssuance] = useState(false);
  const [isOpenBatchForm, setIsOpenBatchForm] = useState(false);
  const [batchId, setBatchId] = useState(false);
  const [isOpenRTOForm, setIsOpenRTOForm] = useState(false);
  const [isHybrid, setIsHybrid] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isRefreshOrderData, setIsRefreshOrderData] = useState(false);
  const [isOpenBrief, setIsOpenBrief] = useState(false);
  const [isLoadingCOD, setIsLoadingCOD] = useState(false);
  const [checkID, setCheckID] = useState(0);
  const [orderId, setOrderId] = useState(false);
  const [zebra, setZebra] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ZEBRA_URL,
  });
  const [barcodesPDF, setBarcodesPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: BARCODES_PDF,
  });
  const [stateReceiptCOD, setStateReceiptCOD] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: COD_RECEIPT_URL,
  });
  const db = new OrderSortingDB(userWarehouseId);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const selectedOrderIds = selectedItems ? selectedItems.map(v => v.id) : [];
  const orderBarcodes = selectedItems ? selectedItems.map(v => v.barcode) : [];

  const [openInbound, setOpenInbound] = useState(null);

  const [periodList, setPeriodList] = useState([]);

  useEffect(() => {
    getArchivePeriod()
      .then(res => {
        setPeriodList(getValue(res, "data", []));
      })
      .catch(() => setPeriodList([]));
  }, []);

  const [error, setError] = useState();
  const [errorOtpData, setErrorOtpError] = useState("");
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line camelcase
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    state: "all",
    use_solr: true,
    warehouse_ids: userWarehouse && userWarehouse.get("id"),
    refresh: false,
    with_totals: true,
    search_type: "order_number",
    archive: true,
    archive_period: null,
  });
  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });
  const [order, setOrder] = useState({});
  const chargeItems = _.get(order, "charge_items", []);
  const chargeItemCOD = _.find(chargeItems, { charge_type: COD }) || {};
  const isHaveCOD = chargeItemCOD.charge > 0;
  const barcode = _.get(order, "barcode");

  const contentItems = _.get(order, "content_items", []);

  const [refreshChekId, setRefreshChekId] = useState(false);

  useEffect(() => {
    if (isHaveCOD && isOpenIssuance && !(checkID > 0)) {
      getCheckId().then(res => setCheckID(_.get(res, "data")));
    }
  }, [refreshChekId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isHaveCOD && isOpenIssuance && !(checkID > 0)) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID, isOpenIssuance, isHaveCOD]);

  useEffect(() => {
    if (orderId) {
      getOrder2(orderId)
        .then(res => {
          setOrder(_.get(res, "data", {}));
          setIsHybrid(_.get(res, "data.hybrid", false));
        })
        .catch(err => showErrorMessage1(err));
    }
  }, [orderId, isRefreshOrderData]);
  useEffect(() => {
    if (getValue(filter, "archive_period.code", null)) {
      setIsLoading(true);

      getOrderList2({
        ...filter,
        archive_period: filter.archive_period.code,
      })
        .then(res => {
          const tempTotal = _.get(res, "data.total");
          setIsLoading(false);
          setOrderList(_.get(res, "data.list"));
          setError(_.get(res, "data.error"));
          setTotal(tempTotal);
        })
        .catch(err => {
          setIsLoading(false);
          showErrorMessage1(err);
        });
    }
  }, [filter]);

  const handleChangePage = (event, page) =>
    setFilter({
      ...filter,
      page,
      with_totals: false,
    });
  const handleChangeRowsPerPage = event =>
    setFilter({
      ...filter,
      size: parseInt(event.target.value, 10),
      page: 0,
      with_totals: false,
    });

  const columns = [
    ...adminColumnOptions,
    {
      ind: 18,
      type: RENDER,
      name: "action",
      label: i18n.get("action", "Action"),
      align: "center",
      withoutIcon: true,
      render: row => (
        <FlexBox
          flex={true}
          align={ALIGN_CENTER}
          justify={JUSTIFY_CENTER}
          style={{ marginRight: 15 }}
        >
          {getValue(row, "payment_status", false) &&
            isNotFinalStatus(getValue(row, "status", null)) &&
            getValue(row, "status", null) !== CANCELLED && (
              <CustomButton
                fullWidth={true}
                variant={OUTLINED}
                color={SECONDARY}
                style={{ minWidth: 200, marginLeft: 15 }}
                onClick={() => {
                  setOpenInbound(getValue(row, "barcode", null));
                }}
              >
                {i18n.get("unarchive", "Unarchive")}
              </CustomButton>
            )}
        </FlexBox>
      ),
    },
  ];

  const allSideMenuItems = (
    <MenuButtonMore>
      <MenuItem onClick={() => setIsOpenDynamicDownloadCSV(true)}>
        {i18n.get("dynamic_download_csv", "Download Dynamic CSV")}
      </MenuItem>
    </MenuButtonMore>
  );
  const checkAVVALres = response => {
    if (response === null) {
      showErrorMessage1(
        i18n.get(
          "unexpected_error_occurred_and_payment_failed_please_contact_your_manage_or_try_later",
        ),
      );
      return false;
    } else if (
      response &&
      getValue(response, "resInfo.result.QRCodeURL") === null
    ) {
      showErrorMessage1(i18n.get("the_payment_was_not_successful"));
      return false;
    } else if (_.get(response, "msg") === "error") {
      const errorMessage = `${i18n.get("error_msg_from_avval")} : ${_.get(
        response,
        "resInfo.message",
      )}`;
      showErrorMessage1(errorMessage);
      return false;
    }
    return true;
  };
  const createAVVALData = (paymentType, cardType, pptId, QQS) => {
    const customerName = _.get(order, "customer.name", "");
    const servicesCod = [];
    const commissionINNItems = [];

    contentItems.map(item => {
      const vat = parseFloat(
        ((Number(_.get(item, "price")) * Number(_.get(item, "qty"))) / 1.12) *
          0.12,
      ).toFixed(2);
      const Amount = Number(_.get(item, "qty")) * 1000;
      const Price =
        Number(_.get(item, "qty")) * Number(_.get(item, "price", 0)) * 100;
      servicesCod.push({
        type: "COD",
        id: Number(_.get(item, "service_id", "")),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });
      return commissionINNItems.push({
        Name:
          _.get(item, "name", "") && _.get(item, "name", "").length
            ? _.get(item, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(item, "barcode", "$10406001001000000$"),
        Label: "",
        Spic: _.get(item, "spic", "10105001001000000"),
        Units: 0,
        Price,
        VatPercent: QQS || 0,
        PackageCode: _.get(item, "package_code", 0),
        CommissionInfo: {
          TIN:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 9
              ? _.get(item, "commission_tin", "")
              : "",
          PINFL:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 14
              ? _.get(item, "commission_tin", "")
              : "",
        },
        Vat: QQS > 0 ? Number((vat * 100).toFixed(0)) : 0,
        Amount,
        OwnerType: 0,
        Discount: _.get(item, "discount", 0),
        Other: _.get(item, "other", 0),
      });
    });

    const ReceivedCashCOD =
      paymentType === CASH
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCardCOD =
      paymentType === CARD
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;

    return {
      method: "sale-services",
      userId: userIdAVVAL,
      serviceId,
      barcode: _.get(order, "barcode", ""),
      checkID,
      chNumber: _.get(order, "chNumber", orderId),
      services: servicesCod,
      request: {
        Items: commissionINNItems,
        ReceivedCash: ReceivedCashCOD,
        ReceivedCard: ReceivedCardCOD,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            cardType === "CORPORATIVE" ? 1 : cardType === "PRIVATE" ? 2 : "",
          PPTID: pptId,
        },
      },
    };
  };

  const otpSettingsServiceTypes = isOtpSettingsServiceType
    ? isOtpSettingsServiceType.toJS()
    : [];
  const otpSettingsTransportationTypes = isOtpSettingsTransportationType
    ? isOtpSettingsTransportationType.toJS()
    : [];

  const showOtpSettings =
    otpSettingsServiceTypes.includes(_.get(order, "service_type.code", "")) &&
    otpSettingsTransportationTypes.includes(
      _.get(order, "transportation_type", ""),
    );

  const optionsPayment = {
    method: "POST",
    headers: {
      Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
      "Content-Type": "application/json",
      Cookie: "PHPSESSID=5lgnfmu5ov5vgb3b8g7tdb9rg2",
    },
    body: JSON.stringify({
      search_chek: {
        barcode,
        method: "sale-services",
      },
    }),
  };

  return (
    <AdminAppLayout title={i18n.get("archive_orders", "Archive Orders")}>
      <ConfirmDialog
        confirmButtonLabel={i18n.get("yes", "Yes")}
        dismissButtonLabel={i18n.get("no", "No")}
        onConfirm={() =>
          batchBarcodesStatusUpdate([
            {
              status: "in_sorting_facility",
              barcodes: [openInbound],
            },
          ])
            .then(() => {
              showSuccessMessage1(i18n.get("successfully"));
              setOpenInbound(null);
              refreshOrderList();
            })
            .catch(err => showErrorMessage1(err))
        }
        open={openInbound}
        onRequestClose={() => setOpenInbound(null)}
      >
        {i18n.get(
          "are_you_sure_you_want_to_move_this_order_from_the_archive_to_outbound",
          "Are you sure you want to move this order from the archive to outbound?",
        )}
      </ConfirmDialog>

      <OrderListErrorNotification
        error={error}
        onFixSizeError={() => setFilter({ ...filter, size: 0 })}
      />
      <DownloadDynamicCSVDialog
        open={isOpenDynamicDownloadCSV}
        getDynamicFields={getDynamicFields}
        createDownloadCSVHref={v =>
          formatDateValuesForDownloadCSV(
            {
              ...filter,
              archive_period: getValue(filter, "archive_period.code", null),
            },
            v,
          ).toJS()
        }
        onRequestClose={() => setIsOpenDynamicDownloadCSV(false)}
      />
      <AdminBatchUpdateOrderDialogWrapper
        title="batch_update"
        open={isOpenBatchForm}
        isOpenUnpaidDialog={selectedItems.find(v => !v.payment_status)}
        initialValues={{
          orderBarcodes: selectedItems
            .filter(v => v.payment_status)
            .map(v => v.barcode),
          privacy: PUBLIC,
          batchType: "inactive",
        }}
        list={orderList}
        onRequestClose={() => setIsOpenBatchForm(false)}
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={response => {
          setBatchId(response.data.id);
          refreshOrderList();
        }}
      />

      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId}
        onRequestClose={() => {
          setIsOpenBatchForm(false);
          setBatchId(false);
        }}
      />
      <BatchRTOOrdersFormDialogWrapper
        open={isOpenRTO}
        isHybrid={isHybrid}
        initialValues={{
          orderBarcodes: [barcode],
          warehouse: { id: userWarehouseId },
        }}
        onRequestClose={() => {
          setIsOpenRTO(false);
          setIsOpenBrief(true);
        }}
        onSubmitSuccess={response => {
          setIsRefreshOrderData(!isRefreshOrderData);
          setIsOpenRTO(false);
          setBatchId(_.get(response, "data.id"));
        }}
      />
      <OrderSortingRedirectOrderDialog
        open={isOpenRedirect}
        order={fromJS(order)}
        onSubmit={({ index }) =>
          updateRecipientPostcode({
            index: fp.get("name", index),
            barcodes: [barcode],
            is_redirected: true,
          }).catch(ResponseError.throw)
        }
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={() => {
          setIsOpenRedirect(false);
          db.batchReloadOrders([barcode])
            .toPromise()
            .then(() => {
              db.batchUpdateOrderBins([barcode], null).toPromise();
              setIsOpenRedirect(false);
            })
            .finally(() => setIsRefreshOrderData(!isRefreshOrderData));
          showSuccessMessage1(i18n.get("successfully_updated"));
        }}
        onRequestClose={() => {
          setIsOpenRedirect(false);
          setIsOpenBrief(true);
        }}
      />
      {orderBarcode && (
        <BatchAssignOrdersToDriverFormDialogWrapper
          open={orderBarcode}
          disableBarcodes={true}
          initialValues={{
            orderBarcodes: [orderBarcode],
            warehouse: { id: userWarehouseId },
          }}
          onRequestClose={() => {
            setLocationQuery(fp.unset("orderBarcode"));
          }}
          onSubmitSuccess={res => {
            setIsRefreshOrderData(!isRefreshOrderData);
            setLocationQuery(fp.unset("orderBarcode"));
            setBatchId(_.get(res, "data.id"));
          }}
        />
      )}
      <BatchAssignOrdersToDriverFormDialogWrapper
        open={isOpenDispatch}
        disableBarcodes={true}
        initialValues={{
          orderBarcodes: [barcode],
          warehouse: { id: userWarehouseId },
        }}
        onRequestClose={() => {
          setIsOpenDispatch(false);
          setIsOpenBrief(true);
        }}
        onSubmitSuccess={res => {
          setIsRefreshOrderData(!isRefreshOrderData);
          setIsOpenDispatch(false);
          setBatchId(_.get(res, "data.id"));
        }}
      />
      <OrderDetailsDialogIssuance2
        errorOtpData={errorOtpData}
        setErrorOtpError={setErrorOtpError}
        showOtpSettings={showOtpSettings}
        isLoadingCOD={isLoadingCOD}
        open={isOpenIssuance}
        isHaveCOD={isHaveCOD}
        chargeItemCOD={_.get(chargeItemCOD, "charge")}
        close={() => {
          setIsOpenIssuance(false);
          setIsOpenBrief(true);
          setIsLoadingCOD(false);
        }}
        initialValues={{
          name: _.get(order, "recipient_data.name", ""),
          without_otp: !showOtpSettings,
        }}
        header={i18n.get("recipient", "Recipient")}
        orderId={orderId}
        onSubmit={values => {
          setIsLoadingCOD(true);
          if (isHaveCOD) {
            fetch(avvalSearchUrl, optionsPayment)
              .then(response => response.json())
              .then(rescheck => {
                if (rescheck && rescheck.res_id === 103) {
                  setReceipt(orderId, {
                    check_number: orderId,
                    cod_payment_type: _.get(values, "codPaymentType", CASH),
                    type: "COD",
                    receipt: {
                      TerminalID: getValue(rescheck, "data.terminal_id"),
                      ReceiptSeq: getValue(rescheck, "data.tartib_raqami"),
                      DateTime: getValue(rescheck, "data.date_time"),
                      FiscalSign: getValue(rescheck, "data.fiskal_belgi"),
                      AppletVersion: getValue(rescheck, "data.versiya"),
                      QRCodeURL: getValue(rescheck, "data.link"),
                      checkID,
                    },
                  })
                    .then(() => {
                      updateOrderIssuance(orderId, toSnakeCase(values))
                        .then(() => {
                          uploadOrderSignature(orderId, {
                            name: values.name,
                            order_id: orderId,
                            phone: "",
                            photo_id: values.photoId,
                            signature_id: values.signatureId,
                            type: "delivery_signature",
                          })
                            .then(res2 => {
                              setIsRefreshOrderData(!isRefreshOrderData);
                              showSuccessMessage1(res2.status);
                              setIsOpenIssuance(false);
                              setCheckID(0);
                              setIsOpenBrief(true);
                            })
                            .catch(err => {
                              setIsLoadingCOD(false);
                              showErrorMessage1(err);
                            })
                            .finally(() => {
                              setIsLoadingCOD(false);
                              getPDF(
                                stateReceiptCOD,
                                setStateReceiptCOD,
                                IS_PRINTING,
                                orderId,
                                showErrorMessage1,
                              );
                            });
                        })
                        .catch(err => {
                          setErrorOtpError(formatErrorMessage(err));

                          setIsLoadingCOD(false);
                          showErrorMessage1(err);
                        });
                    })
                    .catch(err => {
                      setIsLoadingCOD(false);
                      showErrorMessage1(err);
                    });
                } else {
                  fetch(avvalSearchUrl, options(contentItems[0].commission_tin))
                    .then(response => response.json())
                    .then(resAvval => {
                      if (resAvval && resAvval.mess === "success") {
                        const QQS = resAvval.data.nds_active ? 12 : 0;
                        fetch(urlAVVAL, {
                          timeout: 60 * 1000,
                          method: "POST",
                          body: JSON.stringify(
                            createAVVALData(
                              _.get(values, "codPaymentType", CASH),
                              getValue(values, "cardType"),
                              getValue(values, "pptId"),
                              QQS,
                            ),
                          ),
                        })
                          .then(response => response.json())
                          .then(res => {
                            if (res && getValue(res, "code") === "1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo.code")),
                                ),
                              );
                              setIsLoadingCOD(false);
                            } else if (res && getValue(res, "code") === "-1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo")),
                                  getValue(res, "resInfo"),
                                ),
                              );
                              setIsLoadingCOD(false);
                            } else if (
                              res &&
                              (getValue(res, "resInfo.result.QRCodeURL") ===
                                null ||
                                !getValue(res, "resInfo.result.QRCodeURL"))
                            ) {
                              showErrorMessage1(
                                i18n.get("the_payment_was_not_successful"),
                              );
                              setIsLoadingCOD(false);
                            } else if (res && res.msg === "succes") {
                              if (checkAVVALres(res)) {
                                setReceipt(orderId, {
                                  check_number: orderId,
                                  vat_active: resAvval.data.nds_active,
                                  cod_payment_type: _.get(
                                    values,
                                    "codPaymentType",
                                    CASH,
                                  ),
                                  type: "COD",
                                  receipt: {
                                    ..._.get(res, "resInfo.result"),
                                    checkID,
                                  },
                                })
                                  .then(() => {
                                    updateOrderIssuance(
                                      orderId,
                                      toSnakeCase(values),
                                    )
                                      .then(() => {
                                        uploadOrderSignature(orderId, {
                                          name: values.name,
                                          order_id: orderId,
                                          phone: "",
                                          photo_id: values.photoId,
                                          signature_id: values.signatureId,
                                          type: "delivery_signature",
                                        })
                                          .then(res2 => {
                                            setIsRefreshOrderData(
                                              !isRefreshOrderData,
                                            );
                                            showSuccessMessage1(res2.status);
                                            setIsOpenIssuance(false);
                                            setCheckID(0);
                                            setIsOpenBrief(true);
                                          })
                                          .catch(err => {
                                            setIsLoadingCOD(false);
                                            showErrorMessage1(err);
                                          })
                                          .finally(() => {
                                            setIsLoadingCOD(false);
                                            getPDF(
                                              stateReceiptCOD,
                                              setStateReceiptCOD,
                                              IS_PRINTING,
                                              orderId,
                                              showErrorMessage1,
                                            );
                                          });
                                      })
                                      .catch(err => {
                                        setErrorOtpError(
                                          formatErrorMessage(err),
                                        );

                                        setIsLoadingCOD(false);
                                        showErrorMessage1(err);
                                      });
                                  })
                                  .catch(err => {
                                    setIsLoadingCOD(false);
                                    showErrorMessage1(err);
                                  });
                              }
                            } else {
                              setIsLoadingCOD(false);
                            }
                          })
                          .catch(() => {
                            setIsLoadingCOD(false);
                            showErrorMessage1(
                              i18n.get("user_is_not_connected_to_avval"),
                            );
                          });
                      }
                    });
                }
              })
              .catch(() => {
                setIsLoadingCOD(false);
                showErrorMessage1(i18n.get("user_is_not_connected_to_avval"));
              });
          } else {
            updateOrderIssuance(orderId, toSnakeCase(values))
              .then(() => {
                uploadOrderSignature(orderId, {
                  name: values.name,
                  order_id: orderId,
                  phone: "",
                  photo_id: values.photoId,
                  signature_id: values.signatureId,
                  type: "delivery_signature",
                })
                  .then(res => {
                    setIsRefreshOrderData(!isRefreshOrderData);
                    showSuccessMessage1(res.status);
                    setIsOpenIssuance(false);
                    setIsOpenBrief(true);
                  })
                  .catch(err => showErrorMessage1(err));
              })
              .catch(err => {
                setErrorOtpError(formatErrorMessage(err));
                showErrorMessage1(err);
              })
              .finally(() => setIsLoadingCOD(false));
          }
        }}
      />

      <AdminOrderDetailsDialogWrapperForOrderList
        isRefreshOrderData={isRefreshOrderData}
        order={fromJS(order)}
        isOpen={!batchId && isOpenBrief}
        setBatchId={setBatchId}
        setIsOpenIssuance={setIsOpenIssuance}
        refreshOrderData={() => setIsRefreshOrderData(prev => !prev)}
        setIsOpenBrief={setIsOpenBrief}
        orderId={orderId}
        setIsOpenDispatch={setIsOpenDispatch}
        setIsOpenRedirect={setIsOpenRedirect}
        setIsOpenRTO={setIsOpenRTO}
        setOrderId={id => setOrderId(id)}
        refreshOrderList={refreshOrderList}
        onRequestClose={() => {
          setIsOpenBrief(false);
          setOrder({});
          setOrderId(null);
        }}
      />
      {fp.get("open", isOpenBatchRedirectOrders) && (
        <BatchRedirectOrdersFormDialogWrapper
          open={true}
          initialValues={{
            orderBarcodes,
            is_redirected: fp.get("is_redirect", isOpenBatchRedirectOrders),
          }}
          onRequestClose={() => {
            setIsOpenBatchRedirectOrders({ open: false, is_redirect: false });
          }}
          onSubmitSuccess={() => {
            setIsOpenBatchRedirectOrders({ open: false, is_redirect: false });
          }}
        />
      )}

      {isOpenRTOForm && userWarehouse && (
        <BatchRTOOrdersFormDialogWrapper
          open={true}
          initialValues={{
            orderBarcodes,
            warehouse: userWarehouse.toJS(),
          }}
          onRequestClose={() => {
            setIsOpenRTOForm(false);
          }}
          onSubmitSuccess={response => {
            setIsOpenRTOForm(false);
            setBatchId(fp.get("data.id", response));
          }}
        />
      )}

      <AdminArchiveOrderFilterWrapper
        showLogistic={true}
        setSelectedItems={setSelectedItems}
        filter={filter}
        setFilter={setFilter}
        withCustomFilters={true}
        open={isOpenFilter}
        onRequestClose={() => setIsOpenFilter(false)}
        closeDialog={() => setIsOpenFilter(false)}
      />

      <AppTitle title={i18n.get("archive_orders", "Archive Orders")}>
        <FlexBox style={{ padding: 10 }} className={classes.select}>
          <FormControl variant="outlined" style={{ width: 300 }}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              style={{ fontSize: 16 }}
            >
              {i18n.get("period", "Period")}
            </InputLabel>
            <Select
              value={filter.archive_period}
              onChange={event =>
                setFilter(prev => ({
                  ...cleanupValues(prev),
                  page: 0,
                  archive_period: event.target.value,
                }))
              }
              label={i18n.get("period", "Period")}
            >
              {periodList.map((item, index) => (
                <MenuItem key={index} value={item} style={{ fontSize: 16 }}>
                  {`${new Date(
                    item.start_date,
                  ).getFullYear()} ${formatMonthLocalise(
                    new Date(item.start_date),
                    currentLanguage,
                  )} - ${formatMonthLocalise(
                    subDays(new Date(item.end_date), 1),
                    currentLanguage,
                  )}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FlexBox>
      </AppTitle>
      <OrdersMUITable
        filter={filter}
        setFilter={setFilter}
        orderBy={filter.order_by}
        orderByDirection={filter.order_by_direction}
        isLoading={isLoading}
        isCheckboxEnabled={true}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        size="small"
        list={orderList}
        total={total}
        page={filter.page}
        rowsPerPage={filter.size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        setOrderId={setOrderId}
        setIsOpenBrief={setIsOpenBrief}
        columns={columns.map(v => ({ ...v, type: v.type || COLUMN }))}
        setOrderBy={v =>
          setFilter({
            ...filter,
            order_by: v,
            with_totals: false,
            order_by_direction: filter.order_by_direction
              ? filter.order_by_direction === "desc"
                ? "asc"
                : "desc"
              : "asc",
          })
        }
        headerActions={
          <FlexBox
            flex={true}
            align={ALIGN_CENTER}
            justify={JUSTIFY_SPACE_AROUND}
            style={{ padding: ".25rem 1rem" }}
          >
            {selectedOrderIds.length ? (
              <React.Fragment>
                <FlexBox flex={true}>
                  {selectedItems.length}{" "}
                  {i18n.get("items_selected", "item(s) selected")}.
                </FlexBox>
                <FlexBox>
                  <FlexBox>
                    <CustomButton
                      color={SECONDARY}
                      className={classes.pLR1rem}
                      onClick={() =>
                        getPDF(
                          zebra,
                          setZebra,
                          IS_PRINTING,
                          selectedOrderIds,
                          showErrorMessage1,
                        )
                      }
                      endIcon={
                        zebra[IS_PRINTING] ? (
                          <CircularProgress size={20} color="secondary" />
                        ) : (
                          <Print />
                        )
                      }
                    >
                      {i18n.get("address_label")}
                    </CustomButton>
                  </FlexBox>
                  <FlexBox>
                    <CustomButton
                      color={SECONDARY}
                      className={classes.pLR1rem}
                      onClick={() =>
                        getPDF(
                          barcodesPDF,
                          setBarcodesPDF,
                          IS_PRINTING,
                          selectedOrderIds,
                          showErrorMessage1,
                        )
                      }
                      endIcon={
                        barcodesPDF[IS_PRINTING] ? (
                          <CircularProgress size={20} color="white" />
                        ) : (
                          <Print />
                        )
                      }
                    >
                      {i18n.get("orders_barcodes")}
                    </CustomButton>
                  </FlexBox>
                  {hasBatchUpdatePermission && (
                    <FlexBox>
                      <Button
                        className={classes.pLR1rem}
                        color={SECONDARY}
                        onClick={() => setIsOpenBatchForm(true)}
                      >
                        {i18n.get("batch_orders_update", "Batch Orders Update")}
                      </Button>
                    </FlexBox>
                  )}
                </FlexBox>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FlexBox
                  align={ALIGN_CENTER}
                  style={{
                    minWidth: 330,
                    flex: "0 0 auto",
                    paddingRight: ".5rem",
                  }}
                >
                  {/* eslint-disable */}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setFilter({
                        ...filter,
                        search_type: "order_number",
                      })
                    }
                  >
                    {i18n.get("search_by_order_number")}
                  </span>
                  <Switch
                    checked={filter.search_type !== "order_number"}
                    onChange={(e, v) =>
                      v
                        ? setFilter({
                            ...filter,
                            search_type: "all",
                          })
                        : setFilter({
                            ...filter,
                            search_type: "order_number",
                          })
                    }
                    color={SECONDARY}
                    name="checkedB"
                  />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setFilter({
                        ...filter,
                        search_type: "all",
                      })
                    }
                  >
                    {i18n.get("search_everything")}
                  </span>
                  {/* eslint-enable */}
                </FlexBox>
                <div className={classes.search} ref={ref}>
                  {2 === 3 && (
                    <TextField
                      variant="outlined"
                      InputProps={{
                        startAdornment: <Search />,
                        style: {
                          height: 35,
                          padding: "0 12px",
                        },
                      }}
                      value={filter.search}
                      fullWidth={true}
                      placeholder={i18n.get("type_here_to_search")}
                      label={i18n.get("search")}
                      onChange={v =>
                        setFilter({ ...filter, search: v.target.value })
                      }
                    />
                  )}
                  <Search
                    fontSize="large"
                    style={{
                      color: "rgba(0, 0, 0, 0.23)",
                      top: ".5rem",
                      left: ".5rem",
                      position: "absolute",
                    }}
                  />
                  <ChipTextField
                    style={{
                      padding: ".5rem 1rem 0 3rem",
                      maxHeight: divHeight > 86 && "5.8rem",
                      overflow: divHeight > 86 && "auto",
                    }}
                    fullWidth={true}
                    addOnBlur={false}
                    clearOnBlur={false}
                    value={parseString(filter.search)}
                    disableUnderline={true}
                    placeholder={i18n.get("type_here_to_search")}
                    onChange={v =>
                      setFilter({ ...filter, search: stringifyArray(v) })
                    }
                  />
                </div>
              </React.Fragment>
            )}
            <FlexBox align={ALIGN_CENTER}>
              <IconButton
                className={isLoading && classes.refresh}
                tooltip={i18n.get("refresh", "Refresh")}
                onClick={() => refreshOrderList()}
              >
                <Refresh />
              </IconButton>
              {getValue(filter, "archive_period.code", null) && (
                <Tooltip title={i18n.get("filter_orders", "Filter Orders")}>
                  <IconButton onClick={() => setIsOpenFilter(true)}>
                    <FilterList />
                  </IconButton>
                </Tooltip>
              )}
              {allSideMenuItems}
            </FlexBox>
          </FlexBox>
        }
      />
    </AdminAppLayout>
  );
};
AdminArchiveOrderList.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  userWarehouseId: PropTypes.number,
  i18n: PropTypes.instanceOf(Map),
  isOtpSettingsServiceType: PropTypes.instanceOf(List),
  isOtpSettingsTransportationType: PropTypes.instanceOf(List),
  userWarehouse: PropTypes.object,
  location: PropTypes.object,
  hasBatchUpdatePermission: PropTypes.bool,
  userIdAVVAL: PropTypes.number,
  currentLanguage: PropTypes.string,
};
export default enhancer(AdminArchiveOrderList);
